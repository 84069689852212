<div class="app-container">
  <div class="loading-container" *ngIf="isLoading">
    <div class="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div class="portal-header">
    <h5 style="text-align: center;">All Open Claims</h5>
  </div>

  <!-- Filter Box -->
  <div class="search-box-container">
    <mat-form-field class="search-box-form-field">
      <mat-label>Search Claims by Keyword</mat-label>
      <input #search matInput type="text" (blur)="onSearchKeyUp(search)" [value]="searchValue" autofocus>
      <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="onClearClicked(search)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="main-table-container">
    <mat-table mat-table matTableExporter [dataSource]="dataSource" matSort matSortStart="asc"
      #exporter="matTableExporter" (matSortChange)="sortData($event)">
      <!-- <caption>Open Claims</caption> -->

      <ng-container matColumnDef="caseNo">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Case Number</th>
        <td mat-cell *matCellDef="let cases">{{cases.caseNo}}</td>
      </ng-container>

      <ng-container matColumnDef="refNo">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Mk3 Ref</th>
        <td mat-cell *matCellDef="let cases">{{cases.refNo}}</td>
      </ng-container>

      <ng-container matColumnDef="lastNameS">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Insured</th>
        <td mat-cell *matCellDef="let cases">{{cases.lastName}}</td>
      </ng-container>

      <ng-container matColumnDef="situationSuburb">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Suburb</th>
        <td mat-cell *matCellDef="let cases">{{cases.situationSuburb}}</td>
      </ng-container>

      <ng-container matColumnDef="recD">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Date Received</th>
        <td mat-cell *matCellDef="let cases">{{cases.receivedDate | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="lastInspDate">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Last Assessment</th>
        <td mat-cell *matCellDef="let cases">
          <ng-container
            *ngIf="functionsService.getTimeValue(cases.lastInspDate) == functionsService.getTime5555(); else elseValidLastInspDate">
            N/A
          </ng-container>
          <ng-template #elseValidLastInspDate>
            {{cases.lastInspDate | date: 'dd/MM/yyyy'}}
          </ng-template>&nbsp;
        </td>
      </ng-container>

      <ng-container matColumnDef="sentReport">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Assessment Report</th>
        <td mat-cell *matCellDef="let cases">
          <ng-container
            *ngIf="functionsService.getTimeValue(cases.sentReportDate) == functionsService.getTime5555(); else elseValidInspReportDate">
            N/A
          </ng-container>
          <ng-template #elseValidInspReportDate>
            {{cases.sentReportDate | date: 'dd/MM/yyyy'}}
          </ng-template>&nbsp;
        </td>
      </ng-container>

      <ng-container matColumnDef="lastContact">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Last Contact</th>
        <td mat-cell *matCellDef="let cases">{{cases.lastContactDate | date: 'dd/MM/yyyy'}}&nbsp;</td>
      </ng-container>

      <ng-container matColumnDef="contactDays">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Days From Last Contact</th>
        <!-- <td mat-cell *matCellDef="let cases">{{cases.contactDays}}</td> -->
        <td mat-cell *matCellDef="let cases">
          <ng-container *ngIf="cases.contactDays &lt; 5">
            <div style="color:#008000; font-weight: 600;">{{cases.contactDays}}</div>
          </ng-container>
          <ng-container *ngIf="cases.contactDays &gt; 4 && cases.contactDays &lt; 11">
            <div style="color:rgba(240, 158, 6, 0.911); font-weight: 600;">{{cases.contactDays}}</div>
          </ng-container>
          <ng-container *ngIf="cases.contactDays &gt; 10">
            <div style="color:var(--mk3red); font-weight: 600;">{{cases.contactDays}}</div>
          </ng-container>
          &nbsp;
        </td>
      </ng-container>

      <ng-container matColumnDef="isHoldContact">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Hold Contact</th>
        <!-- <td mat-cell *matCellDef="let cases">
          {{cases.holdContact}}
        </td> -->
        <td mat-cell *matCellDef="let cases">
          <ng-container *ngIf="cases.isHoldContact; else elseNo">Yes
          </ng-container>
          <ng-template #elseNo>
            No
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="acceptance">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Claim Accepted</th>
        <td mat-cell *matCellDef="let cases">{{cases.acceptance}}&nbsp;</td>
      </ng-container>

      <ng-container matColumnDef="peril">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Peril</th>
        <td mat-cell *matCellDef="let cases">{{cases.peril}}</td>
      </ng-container>

      <ng-container matColumnDef="emergencyRepair">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Emergency Repair</th>
        <td mat-cell *matCellDef="let cases">
          {{cases.emergencyRepairType}}
        </td>
      </ng-container>

      <ng-container matColumnDef="ecdEstimatedCompletionDate">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Expected Finalisation</th>
        <td mat-cell *matCellDef="let cases">
          <ng-container
            *ngIf="functionsService.getTimeValue(cases.estimatedCompletionDate) == functionsService.getTime5555(); else elseValidECD">
            N/A
          </ng-container>
          <ng-template #elseValidECD>
            {{cases.estimatedCompletionDate | date: 'dd/MM/yyyy'}}
          </ng-template>
          &nbsp;
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="statusModified">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Claim Status</th>
        <td mat-cell *matCellDef="let cases">{{cases.statusModified}}</td>
      </ng-container> -->

      <ng-container matColumnDef="originalStatus">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Progress Status</th>
        <td mat-cell *matCellDef="let cases">{{cases.originalStatus}}</td>
      </ng-container>

      <ng-container matColumnDef="reOpened">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Re-Opened</th>
        <td mat-cell *matCellDef="let cases">{{cases.reOpenedDate | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="'/case-details/'+ row.refNo"
        class="row-hover mat-row"></tr>

      <!-- <ng-container matColumnDef="noRecord">
        <td mat-footer-cell *matFooterCellDef>No records found.</td>
      </ng-container>

      <ng-template [ngIf]="dataSource.data.length === 0">
        <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
      </ng-template> -->

      <!-- <div *ngIf="dataSource.data.length === 0">
        No Records Found!
        </div>


        <ng-container matColumnDef="nodata">
          <td mat-footer-row *matFooterCellDef [colSpan]="displayedColumns.length" 
          style="text-align: center;">No Data Available</td>
        </ng-container>
      
        
       <tfoot 
         [hidden]="dataSource.data.length >0"
        *matFooterRowDef="['nodata']"><tr><td>xxx</td></tr>
       </tfoot> -->


    </mat-table>

    <div *ngIf="dataSource?.data?.length === 0"
      style="text-align: center; margin-bottom: 10px; margin-top: 10px; color: var(--mk3red); font-weight: 600;">
      No Open Claims found having you as the Assessor
    </div>

    <mat-paginator  *ngIf="dataSource?.data?.length" #paginator [pageSize]="pageSize" [pageSizeOptions]="[12, 50, 100, totalRecords]"
      showFirstLastButtons class="mat-paginator" (page)=onPageSizeChange($event)  [length]="totalRecords">
    </mat-paginator>
  </div>

  <div class="center-button-container" style="padding: 0px">
    <button mat-raised-button class="red-button" (click)=onClearClicked(search)>Clear</button>
  </div>
</div>
