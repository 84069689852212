<div class="centered-div" *ngIf="!isLoggedIn()">
  <table>
    <tr>
      <h2 style="color: var(--mk3red);">Mk3 Portal</h2>
    </tr>
    <tr><img src="_assets/mk3-logo.png" alt="logo" style="height:140px; width: 140px; float: center;"></tr>
    <tr>
      <h5><a href="javascript:void(0)" (click)="login()">Sign In</a></h5>
    </tr>
  </table>
</div>

<!-- Responsive Navbar -->
<div *ngIf="isLoggedIn()" class=".app-container">
  <div style="height: 80vh;">
    <mat-toolbar class="topnav">
      <mat-toolbar-row>
        <button mat-icon-button class="button-to-hide" (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        
        <span class="title-name">Mk3 Portal</span>
        <span class="menu-spacer"></span>
        <div class="topnav-container" >
          <!-- The following menu items will be hidden on both SM and XS screen sizes -->
          <a routerLink="/home">Home</a>
          <a routerLink="" (click)="openSearchDialog(true)">Search by
            MK3 Ref</a>
          <a routerLink="" (click)="openSearchDialog(false)">Search by
            Claim No</a>
          <a routerLink="/cases">Search by Keyword</a>
          <a routerLink="/reports/open-claims-list">My Open Claims</a>
          <a routerLink="/reports/all-open-claims">All Open Claims</a>
          <a routerLink="" (click)="logout()">{{getName()}}&nbsp;&nbsp;Sign Out</a>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav #sidenav class="sidenav">
        <mat-nav-list>
          <a mat-list-item routerLink="/home" (click)="sidenav.toggle()">Home</a>
          <a mat-list-item routerLink="" (click)="openSearchDialog(true)">Search by
            MK3 Ref</a>
          <a mat-list-item routerLink="" (click)="openSearchDialog(false)">Search by
            Claim No</a>
          <a mat-list-item routerLink="/cases" (click)="sidenav.toggle()">Search by Keyword</a>
          <a mat-list-item routerLink="/reports/open-claims-list" (click)="sidenav.toggle()">My Open Claims</a>
          <a mat-list-item routerLink="/reports/all-open-claims" (click)="sidenav.toggle()">All Open Claims</a>
          <a mat-list-item routerLink="" (click)="logout()">Sign Out</a>
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content class="sidenav-content">
        <alert></alert>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
