<h1 *ngIf="!isByRefNo" mat-dialog-title style="color: white; background-color: var(--mk3red); text-align: center;">Open
  Claim by Claim Number</h1>
<h1 *ngIf="isByRefNo" mat-dialog-title style="color: white; background-color: var(--mk3red); text-align: center;">Open
  Claim by Mk3
  Reference Number</h1>

<form [formGroup]="form" autocomplete="off" novalidate fxLayout="column wrap" fxLayoutAlign="center center"
  fxLayoutGap="10px">

  <mat-dialog-content *ngIf="isByRefNo" text-align="middle">
    <small>Mk3 RefNo</small>
    <input type="text" digitOnly inputmode="numeric" formControlName="refNo" class="form-control" maxlength="5"
      (keyup.enter)="onSubmit()" />
    <mat-error *ngIf="hasError('refNo', 'maxlength')">You have entered more than 5 characters!</mat-error>
    <mat-error *ngIf="hasError('refNo', 'pattern')">Invalid RefNo!</mat-error>
  </mat-dialog-content>

  <mat-dialog-content *ngIf="!isByRefNo" text-align="middle">
    <small>Claim Number</small> {{caseNo}}
    <input type="text" [ngModel]="caseNo" formControlName="caseNo" class="form-control"
      (ngModelChange)="caseNo = $event.toUpperCase()" (keyup.enter)="onSubmit()" />
    <!-- <mat-error *ngIf="hasError('caseNo', 'maxlength')">You have entered more than 11 characters!</mat-error> maxlength="11" -->
  </mat-dialog-content>
</form>

<mat-dialog-actions *ngIf="isByRefNo" style="justify-content: center;">
  <button mat-button [mat-dialog-close]="refNo" type="button" [disabled]="!form.valid"
    class="btn btn-sm mat-raised-button btn-danger" (click)="onSubmit()" (keyup.enter)="onSubmit()">Open</button>&nbsp;
  <button mat-button mat-dialog-close type="button" mat-raised-button
    class="btn btn-sm mat-raised-button mat-button-base" (click)="onCancel()">Cancel</button>
</mat-dialog-actions>

<mat-dialog-actions *ngIf="!isByRefNo" style="justify-content: center;">
  <button mat-button [mat-dialog-close]="caseNo" type="button" [disabled]="!form.valid"
    class="btn btn-sm mat-raised-button btn-danger" (click)="onSubmit()" (keyup.enter)="onSubmit()">Open</button>&nbsp;
  <button mat-button mat-dialog-close type="button" mat-raised-button
    class="btn btn-sm mat-raised-button mat-button-base" (click)="onCancel()">Cancel</button>
</mat-dialog-actions>
