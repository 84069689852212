import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from "@angular/material/sort";
import { MatTableDataSource } from '@angular/material/table';
import { AccountService, AlertService } from '@app/index';
import { FunctionsService } from '@app/_services/functions.service';
import { Case } from "@app/_models/case";
import { RestService } from '@app/_services/rest.service';
import { AppComponent } from '@app/app.component';
import { VClaim } from '@app/_models/vClaim';
import { groups } from '@app/_services/auth-config';
@Component({
  selector: 'app-open-claims-list',
  templateUrl: './open-claims-list.component.html',
  styleUrls: ['./open-claims-list.component.scss']
})
export class OpenClaimsListComponent implements OnInit {
  [x: string]: any;
  displayedColumns: string[] = ['refNo', 'lastNameS', 'lastInspDate', 'contactDays',
    'isHoldContact', 'originalStatus', 'peril', 'emergencyRepair', 'estimatedCompletionDate', 'situationSuburb'];
  dataSource!: MatTableDataSource<VClaim>;
  isLoading = true;
  isHMAUser = true;
  search = '';
  searchValue = localStorage.getItem('openClaimsSearchText') ? localStorage.getItem('openClaimsSearchText') : '';
  sortColumn = localStorage.getItem('openClaimsSortColumn') != null ? localStorage.getItem('openClaimsSortColumn') : 'refNo';
  sortDirection = localStorage.getItem('openClaimsSortDirection') != null ? localStorage.getItem('openClaimsSortDirection') : 'desc';

  userFullName = '';
  //encryptedConsultantName = '';

  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(private restService: RestService, private accountService: AccountService,
    public functionsService: FunctionsService, public appComponent: AppComponent) {
    this.userFullName = this.appComponent.getName();
    //this.encryptedConsultantName = this.appComponent.getEncryptedConsultantName(this.userFullName);
  }

  async ngOnInit() {
    this.isHMAUser =
    this.appComponent.userGroupsArray.includes(groups.groupHMA);
    //await this.getEncryptedConsultantName(this.userFullName).then(async () => {
    await this.loadDataAsync();
   // });
  }
    
  /*public async getEncryptedConsultantName(consultant: string) {
    this.restService.getEncryptedQueryParam(consultant).subscribe( 
      (data) => {
        this.encryptedConsultantName = data;
      }),
      (error: any) => {
        console.log(error);
        return '';
      }
    return '';
  } */

  sortData(sort: Sort) {
    localStorage.setItem('openClaimsSortColumn', sort.active);
    localStorage.setItem('openClaimsSortDirection', sort.direction);
    return;
  }

  //Fetch latest claims from the REST API
  /*loadData() {
    this.isLoading = true;
    var isAdminUser = false;

    if (this.userFullName == 'Teresa Dunn' || this.userFullName == 'Jay Rupasinghe' || this.userFullName == 'Jacob Towner' || this.userFullName == 'Steve Powney' || 
    this.userFullName == 'Noyola De Rose' || this.userFullName == 'Adrian Matias' || this.userFullName == 'Pears Chiramel')
      isAdminUser = true;

    this.restService.getVClaim(this.userFullName, isAdminUser).subscribe(
      cases => {
        this.dataSource = new MatTableDataSource(cases);

        if (localStorage.getItem('openClaimsFilterState') == null) {
          this.searchValue = '';
        }
        else {
          this.dataSource.filter = JSON.parse(localStorage.getItem('openClaimsFilterState')!);
          this.searchValue = localStorage.getItem('openClaimsSearchText');
        }
        this.matSort.sort(({ id: this.sortColumn, start: this.sortDirection, disableClear: true }) as MatSortable);
        this.dataSource.sort = this.matSort;
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }*/

  async loadDataAsync(recordCount: number = 1000) {
    this.isLoading = true;
    var isAdminUser = false;

    if (this.userFullName == 'Teresa Dunn' || this.userFullName == 'Jay Rupasinghe' || this.userFullName == 'Jacob Towner' || this.userFullName == 'Steve Powney' || 
    this.userFullName == 'Noyola De Rose' || this.userFullName == 'Adrian Matias' || this.userFullName == 'Pears Chiramel' || this.userFullName == 'Hengke Zhu' || 
    this.userFullName == 'Prabesh Bajracharya')
      isAdminUser = true;
      
    await this.restService
      .getPagedVClaims(
        1,
        recordCount /*<= 3years & open*/,
        '',
        this.accountService.clientFriendlyName,
        this.isHMAUser,
        true,
        this.appComponent.encryptedConsultantName, 
        isAdminUser
      )
      .then((reponse) => {
        this.dataSource = new MatTableDataSource(reponse?.records);
        //this.totalCount = reponse?.totalCount ?? 0;
        this.dataSource.filter = JSON.parse(localStorage.getItem('claimSearchFilterState')!);
        this.searchValue = localStorage.getItem('claimSearchSearchText');

        if (this.searchValue != '' && this.searchValue != null) {
          this.dataSource.filter = this.searchValue;
        }
        else
          this.dataSource.filter = "";


        this.matSort.sort(({ id: this.sortColumn, start: this.sortDirection, disableClear: true }) as MatSortable);
        this.dataSource.sort = this.matSort;
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      });
  }

  
  //Reload the data from the database
  refreshData() {
    this.dataSource.data = [...this.dataSource.data];
    this.search = "";
    this.searchValue = '';
    this.paginator._changePageSize(12);
    this.matSort.sort(({ id: this.sortColumn, start: this.sortDirection, disableClear: true }) as MatSortable);
    this.dataSource.sort = this.matSort;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //Filter claims by keyword
  onSearchKeyUp(search: { value: any; }) {
    var currentFilter = search.value;
    this.dataSource.filter = currentFilter;

    localStorage.setItem('openClaimsFilterState', JSON.stringify(this.dataSource.filter));
    localStorage.setItem('openClaimsSearchText', search.value);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //Clear filter claims by keyword
  onClearClicked(search: { value: string; }) {
    search.value = "";
    this.searchValue = '';
    this.search = '';
    localStorage.setItem('openClaimsFilterState', '""');
    localStorage.setItem('openClaimsSearchText', '');
    this.dataSource.filter = "";

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //Return the claim status - Active or Finalised
  /*getClaimStatus(bldCompletion: Date, ctsCompletion: Date) {
    if (bldCompletion == null || ctsCompletion == null) {
      return "Open";
    }
    else {
      return "Finalised";
    }
  }*/

  getClaimStatus(statusClaimTypeID: Number) {
    if (statusClaimTypeID == 1 || statusClaimTypeID == 3) {
      return 'Open';
    } else {
      return 'Finalised';
    }
  }
}
