import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from "@angular/material/sort";
import { MatTableDataSource } from '@angular/material/table';
import { AccountService, AlertService } from '@app/index';
import { Case } from "../../_models/case";
import { RestService } from '../../_services/rest.service';
import { VClaim } from '@app/_models/vClaim';
import { VClaimsAll } from '@app/_models/vClaimsAll';

import { FunctionsService } from '@app/_services/functions.service';
import { AppComponent } from '@app/app.component';
import { groups } from '@app/_services/auth-config';
@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.scss']
})
export class CaseComponent {
  displayedColumns: string[] = ['refNo', 'caseNo', 'lastNameS', 'policyNo', 'recD', 'consultant', 'situationStreet', 'situationSuburb', 'state', 'postCode', 'endToEnd', 'status', 'originalStatus', 'formerCaseNo'];
  dataSource!: MatTableDataSource<VClaimsAll>;
  isLoading = false;
  isHMAUser = true;
  search = '';
  searchValue = localStorage.getItem('claimSearchSearchText') ? localStorage.getItem('claimSearchSearchText') : '';
  sortColumn = localStorage.getItem('claimSearchSortColumn') != null ? localStorage.getItem('claimSearchSortColumn') : 'refNo';
  sortDirection = localStorage.getItem('claimSearchSortDirection') != null ? localStorage.getItem('claimSearchSortDirection') : 'desc';

  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(private restService: RestService, private alertService: AlertService, private accountService: AccountService,
    private functionsService: FunctionsService,private appComponent : AppComponent
  ) {
  }

  async ngOnInit() {
    this.isHMAUser =
      this.appComponent.userGroupsArray.includes(groups.groupHMA);
    this.alertService.clear();
    await this.loadDataAsync();
  }

  sortData(sort: Sort) {
    localStorage.setItem('claimSearchSortColumn', sort.active);
    localStorage.setItem('claimSearchSortDirection', sort.direction);
  }

  //Fetch latest claims from the REST API
  /*async loadData() {
    this.isLoading = true;
    this.restService.getVClaimsAll('', true).subscribe(
      cases => {
        this.dataSource = new MatTableDataSource(cases);
        this.dataSource.filter = JSON.parse(localStorage.getItem('claimSearchFilterState')!);
        this.searchValue = localStorage.getItem('claimSearchSearchText');

        if (this.searchValue != '' && this.searchValue != null) {
          this.dataSource.filter = this.searchValue;
        }
        else
          this.dataSource.filter = "@!@#@!@";


        this.matSort.sort(({ id: this.sortColumn, start: this.sortDirection, disableClear: true }) as MatSortable);
        this.dataSource.sort = this.matSort;
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      error => {
        this.alertService.error(error);
        this.isLoading = false;
      }
    );
  }*/

  async loadDataAsync(recordCount: number = 0) {
    this.alertService.clear();
    this.isLoading = true;

    await this.restService
      .getPagedVClaims(
        1,
        recordCount /*<= 3years & open*/,
        '',
        this.accountService.clientFriendlyName,
        this.isHMAUser,
        true
      )
      .then((reponse) => {
        this.dataSource = new MatTableDataSource(reponse?.records);
        //this.totalCount = reponse?.totalCount ?? 0;
        this.dataSource.filter = JSON.parse(localStorage.getItem('claimSearchFilterState')!);
        this.searchValue = localStorage.getItem('claimSearchSearchText');

        if (this.searchValue != '' && this.searchValue != null) {
          this.dataSource.filter = this.searchValue;
        }
        else
          this.dataSource.filter = "@!@#@!@";


        this.matSort.sort(({ id: this.sortColumn, start: this.sortDirection, disableClear: true }) as MatSortable);
        this.dataSource.sort = this.matSort;
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      });

    this.isLoading = false;
  }


  //Reload the data from the database
  refreshData() {
    if (this.searchValue != '' && this.searchValue != null)
      this.dataSource.filter = this.searchValue;
    else
      this.dataSource.filter = "@!@#@!@";

    this.isLoading = true;
    this.dataSource.data = [...this.dataSource.data];
    this.dataSource.filter = "@!@#@!@";
    this.search = "";
    this.searchValue = '';
    this.paginator._changePageSize(12);
    this.matSort.sort(({ id: this.sortColumn, start: this.sortDirection, disableClear: true }) as MatSortable);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.isLoading = false;
  }

  //Filter claims by keyword
  onSearchKeyUp(search: { value: any; }) {
    if (search.value.length > 3) {
      this.searchValue = search.value;
      localStorage.setItem('claimSearchFilterState', JSON.stringify(this.searchValue));
      localStorage.setItem('claimSearchSearchText', search.value);

      if (this.dataSource && this.searchValue != null) {
        this.dataSource.filter = this.searchValue;
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
      }
    }
  }

  //Clear filter claims by keyword
  onClearClicked(search: { value: string; }) {
    this.dataSource.filter = "@!@#@!@";
    search.value = "";
    this.searchValue = '';
    this.search = '';
    localStorage.setItem('claimSearchFilterState', JSON.stringify(this.dataSource.filter));
    localStorage.setItem('claimSearchSearchText', '');

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //Return the claim status - Active or Finalised
  /*getClaimStatus(bldCompletion: Date, ctsCompletion: Date) {
    if (bldCompletion == null || ctsCompletion == null) {
      return "Open";
    }
    else {
      return "Finalised";
    }
  }*/
  
  getClaimStatus(statusClaimTypeID: Number) {
    if (statusClaimTypeID == 1 || statusClaimTypeID == 3) {
      return 'Open';
    } else {
      return 'Finalised';
    }
  }
}
