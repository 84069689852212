<div class="app-container">
  <div class="loading-container" *ngIf="isLoading">
    <div class="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div class="portal-header">
    <h5 style="text-align: center;">My Open Claims</h5>
  </div>


  <!-- Filter Box -->
  <div class="search-box-container">
    <mat-form-field class="search-box-form-field">
      <mat-label>Search Claims by Keyword</mat-label>
      <input #search matInput type="text" (keyup)="onSearchKeyUp(search)" [value]="searchValue" autofocus>
      <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="onClearClicked(search)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="main-table-container">
    <mat-table mat-table matTableExporter [dataSource]="dataSource" matSort matSortStart="asc"
      #exporter="matTableExporter" (matSortChange)="sortData($event)">
      <ng-container matColumnDef="refNo">
        <th class="w-2" mat-header-cell *matHeaderCellDef mat-sort-header>Ref#</th>
        <td mat-cell *matCellDef="let cases">{{cases.refNo}}</td>
      </ng-container>

      <ng-container matColumnDef="lastNameS">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Ins'd</th>
        <td mat-cell *matCellDef="let cases">{{cases.lastName}}</td>
      </ng-container>

      <ng-container matColumnDef="lastInspDate">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Last Insp</th>
        <td mat-cell *matCellDef="let cases">
          <ng-container
            *ngIf="cases.lastInspDate==null || functionsService.getTimeValue(cases.lastInspDate) == functionsService.getTime5555(); else elseValidLastInspDate">
            N/A
          </ng-container>
          <ng-template #elseValidLastInspDate>
            {{cases.lastInspDate | date: 'dd/MM/yy'}}
          </ng-template>&nbsp;
        </td>
      </ng-container>

      <ng-container matColumnDef="contactDays">
        <th class="w-2" mat-header-cell *matHeaderCellDef mat-sort-header>Contact Days</th>
        <td mat-cell *matCellDef="let cases">
          <ng-container *ngIf="cases.contactDays &lt; 5">
            <div style="color:#008000; font-weight: 600;">{{cases.contactDays}}</div>
          </ng-container>
          <ng-container *ngIf="cases.contactDays &gt; 4 && cases.contactDays &lt; 11">
            <div style="color:rgba(240, 158, 6, 0.911); font-weight: 600;">{{cases.contactDays}}</div>
          </ng-container>
          <ng-container *ngIf="cases.contactDays &gt; 10">
            <div style="color:var(--mk3red); font-weight: 600;">{{cases.contactDays}}</div>
          </ng-container>
          &nbsp;
        </td>
      </ng-container>

      <ng-container matColumnDef="isHoldContact">
        <th class="w-2" mat-header-cell *matHeaderCellDef mat-sort-header>Hold Contact</th>
        <!-- <td mat-cell *matCellDef="let cases">
          {{cases.holdContact}}
        </td> -->
        <td mat-cell *matCellDef="let cases">
          <ng-container *ngIf="cases.isHoldContact; else elseNo">Yes
          </ng-container>
          <ng-template #elseNo>
            No
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="originalStatus">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Progress Status</th>
        <td mat-cell *matCellDef="let cases">{{cases.originalStatus}}</td>
      </ng-container>

      <ng-container matColumnDef="peril">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Peril</th>
        <td mat-cell *matCellDef="let cases">{{cases.peril}}</td>
      </ng-container>

      <ng-container matColumnDef="emergencyRepair">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Emergency Repair</th>
        <td mat-cell *matCellDef="let cases">
          {{cases.emergencyRepairType}}
        </td>
      </ng-container>

      <ng-container matColumnDef="estimatedCompletionDate">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Expected Finalisation</th>
        <td mat-cell *matCellDef="let cases">
          <ng-container
            *ngIf="cases.estimatedCompletionDate==null|| functionsService.getTimeValue(cases.estimatedCompletionDate) == functionsService.getTime5555(); else elseValidECD">
            N/A
          </ng-container>
          <ng-template #elseValidECD>
            {{cases.estimatedCompletionDate | date: 'dd/MM/yyyy'}}
          </ng-template>
          &nbsp;
        </td>
      </ng-container>

      <ng-container matColumnDef="situationSuburb">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Suburb</th>
        <td mat-cell *matCellDef="let cases">{{cases.situationSuburb}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="'/case-details/'+ row.refNo"
        class="row-hover mat-row"></tr>
    </mat-table>

    <div *ngIf="dataSource.data.length === 0 "
      style="text-align: center; margin-bottom: 10px; margin-top: 10px; color: var(--mk3red); font-weight: 600;">
      No Open Claims found having you as the Assessor
    </div>

    <mat-paginator #paginator [pageSize]="12" [pageSizeOptions]="[12, 50, 100, dataSource.data.length]"
      showFirstLastButtons class="mat-paginator">
    </mat-paginator>
  </div>

  <div class="center-button-container" style="padding: 0px">
    <button mat-raised-button class="red-button" (click)=onClearClicked(search)>Clear</button>
  </div>
</div>
