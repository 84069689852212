import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Trades } from '@app/_models/trades';
import { RestService } from '@app/_services/rest.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { groups } from '@app/_services/auth-config';
import { AppComponent } from '@app/app.component';
import { TradesDetail } from '@app/_models/trades-detail';

@Component({
  selector: 'app-trade',
  templateUrl: './trade.component.html',
  styleUrls: ['./trade.component.scss']
})
export class TradeComponent implements OnInit {
  displayedTradesColumns: string[] = [
    'jobId',
    'dateSent',
    'tradingName',
    'isFinalInvoice',
    'invoiceNumber',
    'invoiceAmount',
    'statusName',
    'actionedDate',
    'instructionFinalise'
  ];
  dataSourceTrades!: MatTableDataSource<Trades>;
  isLoading = true;
  tradesDetail !: TradesDetail;
  totalAmountOfInvoicesPaidToDate!:number;
  totalQuoteRequests!:number;
  openExternalWorkInstructions!:number;
  totalInternalTradeWorkInstructions!:number;
  @ViewChild(MatSort, { static: false }) matSort!: MatSort;
  @ViewChild(MatTable)
  table!: MatTable<any>;
  refNo!: number;
  isHMAOrMk3ContractorsUser=true;

  constructor(private location: Location,
    private activatedRoute: ActivatedRoute,
    private restService: RestService,
    public appComponent: AppComponent
    ) {
  this.isHMAOrMk3ContractorsUser =this.appComponent.userGroupsArray.includes(groups.groupHMA) || this.appComponent.userGroupsArray.includes(groups.Mk3Contractors);

    this.activatedRoute.params.subscribe(params => {
      this.refNo = params['refNo'];
    });
   }
   async ngOnInit() {
    await this.restService
    .getTradesByRefNo(this.activatedRoute.snapshot.params.refNo)
    .then((data: any) => {
      if(data?.trades != null) {
        this.totalAmountOfInvoicesPaidToDate = data.totalAmountOfInvoicesPaidToDate;
        this.totalQuoteRequests = data.totalQuoteRequests;
        this.openExternalWorkInstructions = data.openExternalWorkInstructions;
        this.totalInternalTradeWorkInstructions = data.totalInternalTradeWorkInstructions;
        this.dataSourceTrades = new MatTableDataSource(data.trades);
      //   this.dataSourceTrades = new MatTableDataSource(trades);
        this.dataSourceTrades.sort = this.matSort;
        this.isLoading = false;
      }
    });
    
  }
  public onCancel = () => {
    this.location.back();
  }

}
