<div class="app-container">
  <div class="loading-container" *ngIf="isLoading">
    <div class="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <table class="responsive-table" style="width:100%;">
    <tr class="row">
      <td class="cell left-align" *ngIf="case && case.refNo">&nbsp;Mk3 Ref #. {{case.refNo}}</td>
      <td class="cell">
        Insured: {{case && case.insuredFullName ? case.insuredFullName : (case && case.lastName ? case.lastName:'')}}     {{case && case.isVulnerableCustomer ? "    (Vulnerable Customer)":''}}   
      </td>
      <td class="cell right-align" *ngIf="case && case.statusClaimType && case.originalStatus">
        Claim Status: {{case.statusClaimType.trim()}}, {{case.originalStatus.trim()}}&nbsp;
      </td>
    </tr>
  </table>

  <div class="content">
    <div fxLayout="row wrap" fxLayoutGap="10px grid" fxLayoutAlign="stretch">

      <div class="section-header" fxFlex="100%">
        <mat-card class="mat-card-heading">
          <button style="height: 30px;float:left;margin-top: -5px;" (click)="toggleSection(1)">
            <mat-icon>{{ isPolicyDetailsVisible ? 'expand_less' : 'expand_more' }}</mat-icon>
          </button>
          <h6>Policy Details</h6>
        </mat-card>
      </div>

        <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%" *ngIf="isPolicyDetailsVisible">
          <mat-card>
            <mat-card-content>
              <table style="width:100%">
                <tr>
                  <th style="width:50%">Customer</th>
                  <td>
                    {{case && case.insuredFullName ? case.insuredFullName : (case && case.lastName ? case.lastName:'')}}
                  </td>
                </tr>
                <tr>
                  <th>Policy Number</th>
                  <td *ngIf="case && case.policyNumber">{{case.policyNumber}}</td>
                </tr>
                <tr>
                  <th>Risk Address</th>
                  <td *ngIf="case"><a
                      href="https://www.google.com.au/maps/place/{{(case.situationStreet?case.situationStreet:'').replace('/', '%2F')}} {{(case.situationSuburb?case.situationSuburb:'').replace('/', '%2F')}} {{case.state?case.state:''}} {{case.postCode?case.postCode:''}}"
                      target="_blank">
                      {{case.situationStreet ? case.situationStreet : ''}}, {{case.situationSuburb}},
                      {{case.state}} {{case.postCode}}
                    </a>
                  </td>
                </tr>
              </table>
            </mat-card-content>
          </mat-card>
        </div>

        <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%" *ngIf="isPolicyDetailsVisible">
          <mat-card>
            <mat-card-content>
              <table style="width:100%">
                <tr>
                  <th style="width:50%">Building Sum Insured</th>
                  <td *ngIf="case && case.buildingSumInsured">${{case.buildingSumInsured}}</td>
                </tr>
                <tr>
                  <th>Contents Sum Insured</th>
                  <td *ngIf="case && case.contentsSumInsured">${{case.contentsSumInsured}}</td>
                </tr>
                <tr class="empty-row">
                  <th>&nbsp;</th>
                  <td>&nbsp;</td>
                </tr>
              </table>
            </mat-card-content>
          </mat-card>
        </div>

        <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%" *ngIf="isPolicyDetailsVisible">
          <mat-card>
            <mat-card-content>
              <table style="width:100%">
                <tr>
                  <th style="width:50%">Building Excess</th>
                  <td *ngIf="case && case.excessBuilding">${{case.excessBuilding}}</td>
                </tr>
                <tr>
                  <th>Contents Excess</th>
                  <td *ngIf="case && case.excessContents">${{case.excessContents}}</td>
                </tr>
                <tr class="empty-row">
                  <th>&nbsp;</th>
                  <td>&nbsp;</td>
                </tr>
              </table>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="section-header" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
          <mat-card class="mat-card-heading">
            <button style="height: 30px;float:left;margin-top: -5px;" (click)="toggleSection(2)">
              <mat-icon>{{ isContactDetailsVisible ? 'expand_less' : 'expand_more' }}</mat-icon>
            </button>
            <h6>Customer Contact Details</h6>
          </mat-card>
        </div>
        
          <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%" *ngIf="isContactDetailsVisible">
            <mat-card>
              <mat-card-content>
                <table style="width:100%">
                  <tr>
                    <th style="width:50%">Primary Contact</th>
                    <td *ngIf="claimContact && claimContact[0] && claimContact[0].name">{{claimContact[0].name}}
                      <ng-container *ngIf="claimContact && claimContact[0] && claimContact[0].claimContactRoleID">
                        <b>({{getRoleNameById(claimContact[0].claimContactRoleID)}})</b>
                      </ng-container>
                    </td>
                  </tr>
                  <tr>
                    <th style="width:50%">Primary Contact Phone Number</th>
                    <td *ngIf="claimContact && claimContact[0] && claimContact[0].phone">
                      <a href="tel:{{claimContact[0].phone}}">{{claimContact[0].phone}}</a>
                    </td>
                  </tr>
                  <tr>
                    <th style="width:50%">Primary Contact Email</th>
                    <td *ngIf="claimContact && claimContact[0] && claimContact[0].emailAddress">
                      <a href="mailto:{{claimContact[0].emailAddress}}?subject=Mk3 Ref# {{case.refNo}} {{functionsService.encodeURIComponent(case.lastName)}}. {{ insuredRefName }} Claim Number {{case.caseNo}}">{{claimContact[0].emailAddress}}</a>
                    </td>
                  </tr>
                  <tr>
                    <th style="width:50%"><button class="btn" (click)="addContactDetailDialog()">Add</button>
                        <button class="btn" (click)="editContactDetailDialog()">Edit</button></th>
                    <td></td>
                  </tr>
                </table>
              </mat-card-content>
            </mat-card>
          </div>
        

          <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%" *ngIf="isContactDetailsVisible">
            <mat-card>
              <mat-card-content>
                <table style="width:100%">
                  <tr>
                    <th style="width:50%">Contact 2</th>
                    <td *ngIf="claimContact && claimContact[1] && claimContact[1].name">{{claimContact[1].name}}
                      <ng-container *ngIf="claimContact && claimContact[1] && claimContact[1].claimContactRoleID">
                        <b>({{getRoleNameById(claimContact[1].claimContactRoleID)}})</b>
                      </ng-container>
                    </td>
                  </tr>
                  <tr>
                    <th>Contact 2 Phone Number</th>
                    <td *ngIf="claimContact && claimContact[1] && claimContact[1].phone">
                      <a href="tel:{{claimContact[1].phone}}">{{claimContact[1].phone}}</a>
                    </td>
                  </tr>
                  <tr>
                    <th>Contact 2 Email</th>
                    <td *ngIf="claimContact && claimContact[1] && claimContact[1].emailAddress">
                      <a href="mailto:{{claimContact[1].emailAddress}}">{{claimContact[1].emailAddress}}</a>
                    </td>
                  </tr>
                </table>
              </mat-card-content>
            </mat-card>
          </div>
      
          <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%" *ngIf="isContactDetailsVisible">
            <mat-card>
              <mat-card-content>
                <table style="width:100%">
                  <tr>
                    <th style="width:50%">Contact 3</th>
                    <td *ngIf="claimContact && claimContact[2] && claimContact[2].name">{{claimContact[2].name}}
                      <ng-container *ngIf="claimContact && claimContact[2] && claimContact[2].claimContactRoleID">
                        <b>({{getRoleNameById(claimContact[2].claimContactRoleID)}})</b>
                      </ng-container>
                    </td>
                  </tr>
                  <tr>
                    <th>Contact 3 Phone Number</th>
                    <td *ngIf="claimContact && claimContact[2] && claimContact[2].phone">
                      <a href="tel:{{claimContact[2].phone}}">{{claimContact[2].phone}}</a>
                    </td>
                  </tr>
                  <tr>
                    <th>Contact 3 Email</th>
                    <td *ngIf="claimContact && claimContact[2] && claimContact[2].emailAddress">
                      <a href="mailto:{{claimContact[2].emailAddress}}">{{claimContact[2].emailAddress}}</a>
                    </td>
                  </tr>
                </table>
              </mat-card-content>
            </mat-card>
          </div>

      <div class="section-header" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <mat-card class="mat-card-heading">
          <button style="height: 30px;float:left;margin-top: -5px;" (click)="toggleSection(3)">
            <mat-icon>{{ isClaimDetailsVisible ? 'expand_less' : 'expand_more' }}</mat-icon>
          </button>
          <h6>Claim Details</h6>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%" *ngIf="isClaimDetailsVisible">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th style="width:50%">Claim Number</th>
                <td *ngIf="case && case.caseNo">{{case.caseNo}}</td>
              </tr>
              <tr>
                <th>Mk3 Received Date</th>
                <td *ngIf="case && case.receivedDate">{{case.receivedDate | date: 'dd/MM/yyyy'}}</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%" *ngIf="isClaimDetailsVisible">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th style="width:50%">Date of Loss</th>
                <td *ngIf="case && case.dateOfLoss">{{case.dateOfLoss | date: 'dd/MM/yyyy'}}</td>
              </tr>
              <tr>
                <th>Cause of Damage</th>
                <td *ngIf="case && case.peril">{{case.peril}}</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%" *ngIf="isClaimDetailsVisible">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th style="width:50%">Case Manager</th>
                <td *ngIf="case && case.insurerContactName">{{case.insurerContactName}}</td>
              </tr>
              <tr>
                <th>CAT Code</th>
                <td *ngIf="case && case.catastrophe">{{case.catastrophe}}</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="section-header" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <mat-card class="mat-card-heading"> 
          <button style="height: 30px;float:left;margin-top: -5px;" (click)="toggleSection(4)">
            <mat-icon>{{ isAssessmentDetailsVisible ? 'expand_less' : 'expand_more' }}</mat-icon>
          </button>
          <h6>Claim Assessment</h6>
          <!-- <h6>
            <table style="width:100%">
              <tr>
                <td style="width:39%">  <button class="toggle-button" style="height: 30px;float:left;margin-top: -5px;" (click)="toggleSection(4)">
                  <mat-icon>{{ isAssessmentDetailsVisible ? 'expand_less' : 'expand_more' }}</mat-icon></button>
                  Claim Assessment</td>
                <td style="width:30%">Progress Status</td>
                <td style="width:30%">Claim Reserve</td>
              </tr>
            </table>
          </h6> -->
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%" *ngIf="isAssessmentDetailsVisible">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <label style="width:100%; text-align: center;font-weight: bold;text-decoration:underline">Assessment Details</label>
              </tr>
              <tr>
                <th>Mk3 Assessor</th>
                <td *ngIf="case && case.consultant">{{case.consultant}}</td>
              </tr>
              <tr>
                <th>Last Assessment Date</th>
                <td>
                  <ng-container *ngIf="case.inspectionDate === null">
                  </ng-container>
                  <ng-container
                    *ngIf="case.inspectionDate !== null && functionsService.getTimeValue(case.inspectionDate) === functionsService.getTime5555(); else elseValidInspDate">
                    N/A
                  </ng-container>
                  <ng-template #elseValidInspDate>
                    {{case.inspectionDate | date: 'dd/MM/yyyy'}}
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th style="width:50%">Assessment Type</th>
                <td *ngIf="case && case.assessmentType">{{case.assessmentType}}</td>
              </tr>
              <tr>
                <th style="width:50%">First Report Date</th>
                <td>
                  <ng-container *ngIf="case.sentReportDate === null">
                  </ng-container>
                  <ng-container
                    *ngIf="case.sentReportDate !== null && functionsService.getTimeValue(case.sentReportDate) === functionsService.getTime5555(); else elseValidReportSentDate">
                    N/A
                  </ng-container>
                  <ng-template #elseValidReportSentDate>
                    {{ case.sentReportDate | date: 'dd/MM/yyyy' }}
                  </ng-template>
                </td>
              </tr>
              <!-- <tr>
                <th style="width:50%">Claim Acceptance</th>
                <td *ngIf="case && case.acceptance">{{case.acceptance}}</td>
              </tr> -->
              <tr>
                <th style="width:50%">Claim Recommendation</th>
                <td *ngIf="case && case.claimRecommendation">{{case.claimRecommendation}}</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%" *ngIf="isAssessmentDetailsVisible">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <label style="width:100%; text-align: center;font-weight: bold;text-decoration:underline">Progress Status</label>
              </tr>
              <tr>
                <th style="width:50%">Emergency Repair</th>
                <td>{{case.emergencyRepairType}}</td>
              </tr>
              <tr>
                <th style="font-size: small;">Signed SOW received Date</th>
                <td>
                  <ng-container *ngIf="case.dateScopeOfWorkSignedReceived === null">
                  </ng-container>
                  <ng-container
                    *ngIf="case.dateScopeOfWorkSignedReceived !== null && functionsService.getTimeValue(case.dateScopeOfWorkSignedReceived) === functionsService.getTime5555(); else elseValidSOWReceivedDate">
                    N/A
                  </ng-container>
                  <ng-template #elseValidSOWReceivedDate>
                    {{case.dateScopeOfWorkSignedReceived | date: 'dd/MM/yyyy'}}
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th style="font-size: small;">Signed Contract Received</th>
                <td>
                  <ng-container *ngIf="case.signedContractReceived === null">
                  </ng-container>
                  <ng-container
                    *ngIf="case.signedContractReceived !== null && functionsService.getTimeValue(case.signedContractReceived) === functionsService.getTime5555(); else elseValidSigConRecDate">
                    N/A
                  </ng-container>
                  <ng-template #elseValidSigConRecDate>
                    {{case.signedContractReceived | date: 'dd/MM/yyyy'}}
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th style="width:50%">Excess Received Date</th>
                <td>
                  <ng-container *ngIf="case.excessDateReceived === null">
                  </ng-container>
                  <ng-container
                    *ngIf="case.excessDateReceived !== null && functionsService.getTimeValue(case.excessDateReceived) === functionsService.getTime5555(); else elseValidXSReceivedDate">
                    N/A
                  </ng-container>
                  <ng-template #elseValidXSReceivedDate>
                    {{case.excessDateReceived | date: 'dd/MM/yyyy'}}
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th style="font-size: small;">Estimated Completion Date</th>
                <td>
                  <ng-container *ngIf="case.estimatedCompletionDate === null">
                  </ng-container>
                  <ng-container
                    *ngIf="case.estimatedCompletionDate !== null && functionsService.getTimeValue(case.estimatedCompletionDate) === functionsService.getTime5555(); else elseValidECD">
                    N/A
                  </ng-container>
                  <ng-template #elseValidECD>
                    {{case.estimatedCompletionDate | date: 'dd/MM/yyyy'}}
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th style="width:50%">Contents Component Completed Date</th>
                <td> 
                  <ng-container *ngIf="case.contentsCompletionDate === null"; else elseBlock>
                  </ng-container>
                  <ng-container #elseBlock
                    *ngIf="case.contentsCompletionDate !== null && functionsService.getTimeValue(case.contentsCompletionDate) === functionsService.getTime5555(); else elseValidContentsWorksCompletedDate">
                    N/A
                  </ng-container>
                  <ng-template #elseValidContentsWorksCompletedDate>
                    {{case.contentsCompletionDate | date: 'dd/MM/yyyy'}}
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th style="width:50%">Building Component Completed Date</th>
                <td>
                  <ng-container *ngIf="case.buildingCompletionDate === null">
                  </ng-container>
                  <ng-container
                    *ngIf="case.buildingCompletionDate !== null && functionsService.getTimeValue(case.buildingCompletionDate) === functionsService.getTime5555(); else elseValidBuildingWorksCompletedDate">
                    N/A
                  </ng-container>
                  <ng-template #elseValidBuildingWorksCompletedDate>
                    {{case.buildingCompletionDate | date: 'dd/MM/yyyy'}}
                  </ng-template>
                </td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

        <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%" *ngIf="isAssessmentDetailsVisible">
          <mat-card>
            <mat-card-content>
              <table style="width:100%" *ngIf="case.clientID == 5">
			  <tr>
                <label style="width:100%; text-align: center;font-weight: bold;text-decoration:underline">Claim Reserve</label>
              </tr>
                <tr>
                  <th>Initial Site Building Reserve</th>
                  <td *ngIf="case.buildingPriceRange != null">{{case.buildingPriceRange}}</td>
                </tr>
                <tr>
                  <th>Section 1: Building Reserve</th>
                  <td *ngIf="case.buildingEstimate === null && case.restorationEstimate === null"></td>
                  <td *ngIf="case.buildingEstimate !== null || case.restorationEstimate !== null">
                    ${{(case.buildingEstimate ? case.buildingEstimate : 0) + (case.restorationEstimate ?
                    case.restorationEstimate : 0) | number :
                    '1.2-2'}}</td>
                </tr>
                <tr>
                  <th>Section 2: Contents Reserve</th>
                  <td *ngIf="case.contentsEstimate != null">${{case.contentsEstimate | number :
                    '1.2-2'}}</td>
                </tr>
                <tr>
                  <th style="width:50%">Section 4: Loss of Rent</th>
                  <td *ngIf="case.lorAmountSettled != null">${{case.lorAmountSettled | number :
                    '1.2-2'}}</td>
                </tr>
                <tr>
                  <th style="width:50%">Emergency Make Safe</th>
                  <td *ngIf="case.emergencyRepairEst != null">${{case.emergencyRepairEst | number :
                    '1.2-2'}}</td>
                </tr>
                <tr>
                  <th style="width:50%">Mk3 Fees</th>
                  <td *ngIf="case.mk3Fee != null">${{case.mk3Fee | number : '1.2-2'}}</td>
                </tr>
                <tr>
                  <th style="width:50%">Total Reserve</th>
                  <td>
                    <ng-container *ngIf="case.emergencyRepairEst === null && 
                                          case.restorationEstimate === null && 
                                          case.buildingEstimate === null && 
                                          case.contentsEstimate === null && 
                                          case.lorAmountSettled === null && 
                                          case.tempAccomCost === null
                                          ; else elseTotalNotNull">
                    </ng-container>
                    <ng-template #elseTotalNotNull>
                      ${{(case.emergencyRepairEst ? case.emergencyRepairEst : 0) + 
                        (case.restorationEstimate ? case.restorationEstimate : 0) +
                        (case.buildingEstimate ? case.buildingEstimate: 0) +
                        (case.contentsEstimate ? case.contentsEstimate : 0) +
                        (case.lorAmountSettled ? case.lorAmountSettled : 0) + 
                        (case.tempAccomCost ? case.tempAccomCost: 0)
                         | number : '1.2-2'}} 
                    </ng-template>
                  </td>
                </tr>
              </table>

              <table style="width:100%; line-height:1.1;"  *ngIf="case.clientID !== 5">
			  <tr>
                <label style="width:100%; text-align: center;font-weight: bold;text-decoration:underline">Claim Reserve</label>
              </tr>
                <tr>
                  <th class="table-header-cd">Emergency repair estimate</th>
                  <td *ngIf="case.emergencyRepairEst != null">${{case.emergencyRepairEst | number : '1.2-2'}}</td>
                </tr>
                <tr>
                  <th class="table-header-cd">Restoration estimate</th>
                  <td *ngIf="case.restorationEstimate != null">${{case.restorationEstimate | number : '1.2-2'}}</td>
                </tr>
                <tr>
                  <th class="table-header-cd">Bld estimate</th>
                  <td *ngIf="case.buildingEstimate != null">${{case.buildingEstimate | number : '1.2-2'}}</td>
                </tr>
                <tr>
                  <th class="table-header-cd">Cts estimate</th>
                  <td *ngIf="case.contentsEstimate != null">${{case.contentsEstimate | number : '1.2-2'}}</td>
                </tr>
                <tr>
                  <th class="table-header-cd">Bld Amount settled</th>
                  <td *ngIf="case.buildingAmountSettled != null">${{case.buildingAmountSettled | number : '1.2-2'}}</td>
                </tr>
                <tr>
                  <th class="table-header-cd">Cts Amount settled</th>
                  <td *ngIf="case.contentsAmountSettled != null">${{case.contentsAmountSettled | number : '1.2-2'}}</td>
                </tr>
                <tr>
                  <th class="table-header-cd">LOR Amount settled</th>
                  <td *ngIf="case.lorAmountSettled != null">${{case.lorAmountSettled | number : '1.2-2'}}</td>
                </tr>
                <tr>
                  <th class="table-header-cd">Temporary Accom Estimate</th>
                  <td *ngIf="case.tempAccomCost != null">${{case.tempAccomCost | number : '1.2-2'}}</td>
                </tr>
                <tr>
                  <th class="table-header-cd">Restoration Amount settled</th>
                  <td *ngIf="case.restorationSettledAmount != null">${{case.restorationSettledAmount | number : '1.2-2'}}</td>
                </tr>
                <tr>
                  <th class="table-header-cd">Total Reserve</th>
                  <td>
                    <ng-container *ngIf="case.emergencyRepairEst === null && 
                                          case.restorationEstimate === null && 
                                          case.buildingEstimate === null && 
                                          case.contentsEstimate === null && 
                                          case.lorAmountSettled === null && 
                                          case.tempAccomCost === null
                                          ; else elseTotalNotNull">
                    </ng-container>
                    <ng-template #elseTotalNotNull>
                      ${{(case.emergencyRepairEst ? case.emergencyRepairEst : 0) + 
                        (case.restorationEstimate ? case.restorationEstimate : 0) +
                        (case.buildingEstimate ? case.buildingEstimate: 0) +
                        (case.contentsEstimate ? case.contentsEstimate : 0) +
                        (case.lorAmountSettled ? case.lorAmountSettled : 0) + 
                        (case.tempAccomCost ? case.tempAccomCost: 0)
                         | number : '1.2-2'}} 
                    </ng-template>
                  </td>
                </tr>
              </table>

          </mat-card-content>
        </mat-card>
      </div>

      <div class="section-header" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <mat-card class="mat-card-heading">
          <button style="height: 30px;float:left;margin-top: -5px;" (click)="toggleSection(5)">
            <mat-icon>{{ isContactDatesVisible ? 'expand_less' : 'expand_more' }}</mat-icon>
          </button>
          <h6>Customer Contact Dates</h6>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%" *ngIf="isContactDatesVisible">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th style="width:50%">Date First Contacted</th>
                <td>
                  <ng-container *ngIf="case.firstContactDate === null">
                  </ng-container>
                  <ng-container
                    *ngIf="case.firstContactDate !== null && functionsService.getTimeValue(case.firstContactDate) === functionsService.getTime5555(); else elseValidFirstContactDate">
                    N/A
                  </ng-container>
                  <ng-template #elseValidFirstContactDate>
                    {{case.firstContactDate | date: 'dd/MM/yyyy'}}
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th>Date Last Contacted</th>
                <td>
                  <ng-container *ngIf="case.lastContactDate === null">
                  </ng-container>
                  <ng-container
                    *ngIf="case.lastContactDate !== null && functionsService.getTimeValue(case.lastContactDate) === functionsService.getTime5555(); else elseValidLastContactDate">
                    N/A
                  </ng-container>
                  <ng-template #elseValidLastContactDate>
                    {{case.lastContactDate | date: 'dd/MM/yyyy'}}
                  </ng-template>
                </td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%" *ngIf="isContactDatesVisible">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th style="width:50%">Hold Customer Contact</th>
                <td>
                  <ng-container *ngIf="case.isHoldContact; else elseNo">Yes
                  </ng-container>
                  <ng-template #elseNo>
                    No
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th>Days Since Last Contact</th>
                <td>
                  <ng-container *ngIf="case.statusClaimTypeID != 1; else elseValidContactDays">
                    N/A
                  </ng-container>
                  <ng-template #elseValidContactDays>
                    {{case.contactDays}}
                  </ng-template>
                </td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%" *ngIf="isContactDatesVisible">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr class="empty-row">
                <th>&nbsp;</th>
                <td>&nbsp;</td>
              </tr>
              <tr class="empty-row">
                <th>&nbsp;</th>
                <td>&nbsp;</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>


      <div class="section-header" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <mat-card class="mat-card-heading">
          <button style="height: 30px;float:left;margin-top: -5px;" (click)="toggleSection(6)">
            <mat-icon>{{ isDescriptionDatesVisible ? 'expand_less' : 'expand_more' }}</mat-icon>
          </button>
          <h6>Claim Description and Instruction</h6>
        </mat-card>
      </div>

      <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" *ngIf="isDescriptionDatesVisible">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <td class="td-nopadding">{{case.comments}}</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

    </div>

 
  <div style="text-align: center;align-items: center;margin-top: 20px;">
    <button *ngIf="!isHMAOrMk3ContractorsUser" mat-raised-button class="red-button" (click)='viewTrade()'>View Trade</button>
    <button *ngIf="(!isAlreadySent && isHMAOrMk3ContractorsUser) || isAssessorOrSAMOrManagement" mat-raised-button class="red-button" (click)='addPreliminaryInspection()'>Add/Edit Preliminary Inspection Report</button>
</div>

  <div class="content">
    <div fxLayout="row wrap" fxLayoutGap="10px grid">

      <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <mat-card class="mat-card-heading">
          <h6>Notes</h6>
        </mat-card>

      </div>
    </div>
  </div>
  <!-- <div style="text-align: center;align-items: center;justify-content: center;display: flex;" *ngIf="!isLoading"> -->
  <div class="button-container" *ngIf="!isLoading">
    <ng-select style="max-width: 350px;min-width: 250px;align-items: center;margin-top: 10px;" [items]="claimNoteFilterOption" required 
                      [searchable]="false"
                      [clearable]="false" 
                      bindLabel="optionText"
                      bindValue="id"
                      placeholder="Choose the notes option" (change)='FilterNotes($event)'>
    </ng-select> 
    <div style="display: inline-flex; margin-top: 10px;">
      <button style="width: fit-content;" mat-raised-button class="btn" (click)='AddNewNote()'>Add New Note</button>&nbsp;
      <button mat-raised-button class="btn" (click)=goBack()>Back</button>
    </div>
  </div>

  <div class="mat-elevation-z8">
    <!-- Claim Notes Table -->
    <mat-table class="notes-table" [dataSource]="dataSourceNotes" matSort matSortStart="asc">
      <ng-container matColumnDef="date">
        <th class="w-2" mat-header-cell *matHeaderCellDef mat-sort-header>Action Date</th>
        <td mat-cell *matCellDef="let notes">{{notes.date | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="addedBy">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Added By</th>
        <td mat-cell *matCellDef="let notes">{{functionsService.getDisplayName(notes.addedBy)}}</td>
      </ng-container>

      <ng-container matColumnDef="initiatedBy">
        <th class="w-2" mat-header-cell *matHeaderCellDef mat-sort-header>Initiated By</th>
        <td mat-cell *matCellDef="let notes">{{notes.initiatedBy}}</td>
      </ng-container>

      <ng-container matColumnDef="notes">
        <th style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Notes</th>
        <td mat-cell *matCellDef="let notes" style="text-align: justify;" [innerHTML]="notes.notes"></td>
      </ng-container>
      <!-- Removes notes inline styles
            style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden;
            max-width:1px;padding-left:5px;padding-right:5px;text-align: left;" -->

      <ng-container matColumnDef="private">
        <th class="w-2" mat-header-cell *matHeaderCellDef mat-sort-header>Mk3 Only</th>
        <td mat-cell *matCellDef="let notes">
          <p style="border: 0px;" *ngIf="notes.private==-1">Yes</p>
          <p style="border: 0px;" *ngIf="notes.private==0">No</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="followUpDate">
        <th class="w-2" mat-header-cell *matHeaderCellDef mat-sort-header>Follow-Up Date</th>
        <td mat-cell *matCellDef="let notes">{{notes.followUpDate | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="typeDescription">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Note Type</th>
        <td mat-cell *matCellDef="let notes">{{notes.typeDescription}}</td>
      </ng-container>

      <ng-container matColumnDef="subTypeDescription">
        <th class="w-2" mat-header-cell *matHeaderCellDef mat-sort-header>Contact Type</th>
        <td mat-cell *matCellDef="let notes">{{notes.subTypeDescription}}</td>
      </ng-container>

      <ng-container matColumnDef="reasonDescription">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Reason</th>
        <td mat-cell *matCellDef="let notes">{{notes.reasonDescription}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedNotesColumns"></tr>
      <tr mat-row class="row-hover mat-row" [class.activeNoteRow]="activeNoteRow === row"
        (click)="openNoteDetailsDialog(row, row.notes)" *matRowDef="let row; columns: displayedNotesColumns;"
        [ngClass]="{'highlight-table-row': row.subTypeDescription == 'Insurer Portal'}"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9999">
          No notes found for this claim
        </td>
      </tr>
    </mat-table>
  </div>
  <!-- <div class="center-button-container" *ngIf="!isLoading">
    <button style="width: fit-content;" mat-raised-button class="btn" (click)='AddNewNote()'>Add New
      Note</button>&nbsp;
    <button mat-raised-button class="btn" (click)=goBack()>Back</button>
  </div> -->
</div>