<!-- The home component template contains html and angular 10 template syntax for displaying a simple welcome message and a link to the users section. -->
<div class="app-container">
  <div class="portal-header">
    <h5 style="text-align: center;">Mk3 Portal</h5>
  </div>
  <div class="home-container">
    <div class="p-4">
      <div class="centered-div">
        <table>
          <tr>
            <h4>Hello {{appComponent.getName()}}!</h4>
          </tr>
          <tr>
            <p>You're logged in to the online portal of Mk3 Pty Ltd</p>
          </tr>
          <tr>
            <button mat-button (click)=openSearchDialog(true) class="btn btn-sm mat-raised-button btn-danger">Search by
              MK3 Ref</button>&nbsp;
            <button mat-button (click)=openSearchDialog(false) class="btn btn-sm mat-raised-button btn-danger">Search by
              Claim Number</button>&nbsp;
            <button mat-button [routerLink]="'./cases'" class="btn btn-sm mat-raised-button btn-danger">Search by
              Keyword</button>&nbsp;
            <button mat-button [routerLink]="'./reports/open-claims-list'"
              class="btn btn-sm mat-raised-button btn-danger">My Open Claims</button>&nbsp;
            <button mat-button [routerLink]="'./reports/all-open-claims'"
              class="btn btn-sm mat-raised-button btn-danger">All Open Claims</button>&nbsp;
          </tr>
          <!-- <button [routerLink]="'./reports'" class="btn btn-sm btn-danger">View Reports</button> -->
        </table>
      </div>
    </div>

  </div>
</div>
