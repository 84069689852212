<section fxLayout="row wrap" fxLayoutAlign="center center" class="row-height" class="bdrop">
  <mat-card fxFlex="600px" fxFlex.xs="100%">
    <mat-card-title style="color: white; background-color: var(--mk3red); text-align: center;">Add a new note to Claim
      {{this.refNo}} {{this.lastNameS}}</mat-card-title>
    <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="createNote(form.value)" fxLayout="column wrap"
      fxLayoutAlign="center center" fxLayoutGap="10px">
      <mat-card-content>
        <mat-form-field>
          <!-- readonly  -->
          <input matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="noteDate" id="noteDate"
            (click)="picker.open()" [max]="today">
          <mat-datepicker-toggle matSuffix [for]="picker" style='height: 5em'></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="hasError('noteDate', 'required')">Valid date is required</mat-error>
        </mat-form-field>

        <small>Select Note Type</small>
        <select placeholder="Select note type" formControlName="noteTypeId" class="form-control" style="display: flex;">
          <option *ngFor="let item of noteTypes | async" [ngValue]="item.noteTypeId">
            {{ item.typeDescription }}
          </option>
        </select>

        <mat-form-field style="text-align: center;display: inline;">
          <textarea matInput rows="8" width="800px" maxlength="2500" placeholder="Enter New Note"
            formControlName="noteDescription"></textarea>
          <mat-hint align="end">Not more then 2500 characters long.</mat-hint>
          <mat-error *ngIf="hasError('noteDescription', 'required')">Note is required</mat-error>
          <mat-error *ngIf="hasError('noteDescription', 'maxlength')">You have more than 2500 characters</mat-error>
        </mat-form-field>

      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button class="btn btn-danger" [disabled]="!form.valid">Add Note</button>
        <button type="button" mat-raised-button class="btn btn-danger" (click)="onCancel()">Cancel</button>
      </mat-card-actions>
    </form>
  </mat-card>
</section>
