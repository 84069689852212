<div *ngIf="(!isAlreadySent && isHMAOrMk3ContractorsUser) || isAssessorOrSAMOrManagement" fxLayout="row wrap" fxLayoutAlign="center center" class="row-height" class="bdrop">
  <div class="loading-container" *ngIf="isLoading">
    <div class="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
    <mat-card fxFlex="600px" fxFlex.xs="100%">
      <mat-card-title style="color: white; background-color: var(--mk3red); text-align: center;">Preliminary Inspection Report for
        {{this.refNo}} {{this.lastNameS}}</mat-card-title>
      <form [formGroup]="form" autocomplete="off" novalidate fxLayout="column wrap"
        fxLayoutAlign="center center" fxLayoutGap="10px">
        <mat-card-content>
          <span style="font-size: small;margin-top: 10px;font-weight: bold;">Claim Details</span>
          <br>
          <small>Recorded Date of Loss </small>
          <br>
          {{preliminaryInspectionDetails.recordedDateOfLoss}}
          <br>
          <mat-form-field>
            <!-- readonly  -->
            <input matInput [matDatepicker]="picker" placeholder="Confirmed Date of Loss" formControlName="confirmedDateOfLoss" id="confirmedDateOfLoss"
              (click)="picker.open()" [max]="today" [(ngModel)]="preliminaryInspectionDetails.confirmedDateOfLoss" (ngModelChange)="onConfirmedDateOfLossChange()">
            <mat-datepicker-toggle matSuffix [for]="picker" style='height: 5em'></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <br>
          <input type="checkbox" [checked]=isYetToBeConfirmed (change)="onYetToBeConfirmedCheckboxChange($event)"> Date of Loss yet to be confirmed
          <br>
          <small>Claim Recommendation</small>
                   <ng-select [items]="preliminaryInspectionDDL.claimRecommendationOption" formControlName="claimRecommendation" required 
                            [searchable]="false"
                            [clearable]="false" 
                            bindLabel="optionText"
                            bindValue="id"
                            placeholder="Choose Status"
                            [(ngModel)]="preliminaryInspectionDetails.claimRecommendationOptionId">
                 </ng-select> 
                 <span style="font-size: small;margin-top: 10px;font-weight: bold;">Inspection Details</span>
                 <br>
                 <small>Emergency Works</small>
                 <ng-select [items]="preliminaryInspectionDDL.emergencyRepairType" formControlName="emergencyWork"
                            [searchable]="false"
                            [clearable]="false" 
                            bindLabel="emergencyRepairTypeName"
                            bindValue="emergencyRepairId"
                            placeholder="Choose an item"
                            [(ngModel)]="preliminaryInspectionDetails.emergencyRepairId">
                 </ng-select> 
         
          
          <small>Specialist Report required</small>
          <ng-select [items]="preliminaryInspectionDDL.specialist" formControlName="specialistReportRequired"
                            [searchable]="false"
                            [clearable]="false" 
                            bindLabel="specialistName"
                            bindValue="specialistId"
                            placeholder="Choose an item"
                            [(ngModel)]="preliminaryInspectionDetails.specialistId">
                 </ng-select> 
          <small>Property Management documents</small>
          <ng-select [items]="preliminaryInspectionDDL.propertyManagementStatus" formControlName="PropertyManagementDocuments"
          [searchable]="false"
          [clearable]="false" 
          bindLabel="propertyManagementStatusName"
          bindValue="propertyManagementStatusId"
          placeholder="Choose an item"
          [(ngModel)]="preliminaryInspectionDetails.propertyManagementStatusId">
        </ng-select> 
        <mat-form-field>
          <!-- readonly  -->
          <input matInput [matDatepicker]="picker1" placeholder="Inspection Date" formControlName="inspectionDate" id="inspectionDate"
            (click)="picker.open()" [max]="today" [(ngModel)]="preliminaryInspectionDetails.inspectionDate">
          <mat-datepicker-toggle matSuffix [for]="picker1" style='height: 5em'></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <mat-error *ngIf="hasError('inspectionDate', 'required')">Valid date is required</mat-error>
        </mat-form-field>
        <br>
          <small>Attended By</small>
          <input formControlName="attendedBy" type="text" class="form-control"  style="display: flex;" [value]="" maxlength="255" [(ngModel)]="preliminaryInspectionDetails.attendedBy"/>
          <span style="font-size: small;margin-top: 10px;font-weight: bold;">Initial Site Reserve</span>
          <br>
          <span style="font-size:x-small;font-weight: bold;color: cornflowerblue;">(Building reserve to include Make Safe and Restoration estimates)</span>
          <br>
          <small>Building</small>
          <ng-select [items]="preliminaryInspectionDDL.priceRange" formControlName="buildingPriceRangeId"
                            [searchable]="false"
                            [clearable]="false" 
                            bindLabel="priceRange"
                            bindValue="priceRangeId"
                            placeholder="Choose an item"
                            [(ngModel)]="preliminaryInspectionDetails.buildingPriceRangeId">
                 </ng-select> 
          <!-- <input formControlName="buildingEstimate" type="number" class="form-control" placeholder="$" style="display: flex;" [value]="" [(ngModel)]="preliminaryInspectionDetails.buildingEstimate"/> -->
          <small>Contents</small>
          <ng-select [items]="preliminaryInspectionDDL.priceRange" formControlName="contentsPriceRangeId"
                            [searchable]="false"
                            [clearable]="false" 
                            bindLabel="priceRange"
                            bindValue="priceRangeId"
                            placeholder="Choose an item"
                            [(ngModel)]="preliminaryInspectionDetails.contentsPriceRangeId">
                 </ng-select> 
                 <mat-error *ngIf="hasError('inspectionDate', 'required')">inspection date is required</mat-error>
                 <small>Loss of Rent</small>
          <ng-select [items]="preliminaryInspectionDDL.priceRange" formControlName="lossOfRentPriceRangeId"
                            [searchable]="false"
                            [clearable]="false" 
                            bindLabel="priceRange"
                            bindValue="priceRangeId"
                            placeholder="Choose an item"
                            [(ngModel)]="preliminaryInspectionDetails.lossOfRentPriceRangeId">
                 </ng-select> 
          <!-- <input formControlName="contentsEstimate"  type="number" class="form-control" placeholder="$" style="display: flex;" [value]="" [(ngModel)]="preliminaryInspectionDetails.contentsEstimate"/> -->
          <!-- <mat-form-field style="text-align: center;display: inline;">
            <textarea matInput placeholder="Inspection Summary"
              formControlName="preliminaryInspectionDescription" class="test-area-input" [(ngModel)]="preliminaryInspectionDetails.preliminaryInspectionDescriptionId"></textarea>
            <mat-error *ngIf="hasError('preliminaryInspectionDescription', 'required')">Inspection Summary is required</mat-error>
          </mat-form-field> -->
          <small>Inspection Summary</small>
          <ng-select class="ddldescription"  [items]="preliminaryInspectionDDL.preliminaryInspectionDescription"  formControlName="preliminaryInspectionDescriptionId"
                            [searchable]="false"
                            [clearable]="false" 
                            bindLabel="preliminaryInspectionDescription"
                            bindValue="preliminaryInspectionDescriptionId"
                            placeholder="Choose an item"
                            [(ngModel)]="preliminaryInspectionDetails.preliminaryInspectionDescriptionId" [multiple]="true">
                            <ng-template ng-label-tmp let-item="item">
                              <div [title]="item.preliminaryInspectionDescription" class="txtdescription">{{ item.preliminaryInspectionDescription }}</div>
                          </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                              <div >
                                <span [title]="item.preliminaryInspectionDescription" class="txtdescription">
                                  {{ item.preliminaryInspectionDescription }}
                                </span>
                              </div>
                            </ng-template>
                 </ng-select> 
          <small>Additional Info</small>
          <textarea formControlName="additionalInfo" type="text" class="form-control ddldescription"  style="display: flex;" [value]="" [(ngModel)]="preliminaryInspectionDetails.additionalInfo"></textarea>
  
        </mat-card-content>
        <div class="center-button-container" *ngIf="!isLoading"></div>
        <mat-card-actions>
          <button mat-raised-button class="red-button" style="width: 75px;" [disabled]="!form.valid || isLoading" (click)="savePreliminaryInspection()">Save</button>
          <button type="button" mat-raised-button class="red-button" style="width: 75px;" (click)="onCancel()">Cancel</button>
          <button mat-raised-button class="red-button" style="width: 75px;" [disabled]="!form.valid || !preliminaryInspectionDetails.preliminaryInspectionReportId || isLoading" (click)="getPreliminaryInspectionReport(preliminaryInspectionDetails.preliminaryInspectionReportId)">View</button>
          <button mat-raised-button class="red-button" style="width: 75px;" [disabled]="!form.valid || isLoading" (click)="savePreliminaryInspection(true)">Send</button>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
  <div fxLayout="row wrap" *ngIf="!((!isAlreadySent && isHMAOrMk3ContractorsUser) || isAssessorOrSAMOrManagement)">
    <div>
        <h4>You don't have access to this page!</h4>
    </div>
</div>

  
