<div class="loading-container" *ngIf="isLoading">
  <div class="lds-grid">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
<h5 mat-dialog-title>Contact Details</h5>

<div style="overflow-y: auto; overflow-x: hidden; max-height: 600px;">
  <div *ngFor="let contact of claimContact; let i = index">
    <div class="row">
      <div class="col-md-2">
        <label for="name">Name</label>
        <input [value]="contact.name" 
               [attr.disabled]="!isEditable[i] ? true : null" 
               (input)="updateField($event, contact, 'name')" 
               class="form-control">
      </div>
      <div class="col-md-2">
        <label for="role">Role</label>
        <select [(ngModel)]="contact.claimContactRoleID"
                [disabled]="!isEditable[i]"
                class="form-control"
                name="role"
                #role="ngModel"
                required>
          <option value="" disabled>Select Role</option>
          <option *ngFor="let role of claimContactRoles" [value]="role.contactTypeID" >{{ role.contactPerson }}</option>
        </select>
        <div *ngIf="(contact.claimContactRoleID==0||contact.claimContactRoleID==null )" class="text-danger">
          Role is required.
        </div>
      </div>
      <div class="col-md-2">
        <label for="phone">Phone</label>
        <input [value]="contact.phone" 
               [attr.disabled]="!isEditable[i] ? true : null" 
               (input)="validateAndSetPhone($event, contact)" 
               class="form-control">
      </div>
      <div class="col-md-3">
        <label for="emailAddress">Email</label>
        <input [value]="contact.emailAddress" 
               [attr.disabled]="!isEditable[i] ? true : null" 
               (input)="updateField($event, contact, 'emailAddress')" 
               class="form-control">
      </div>
      <div class="col-md-2">
        <label for="primaryContact" class="mb-0 mr-3">Primary Contact? </label>
        <input type="checkbox" 
               [checked]="contact.primaryContact" 
               [attr.disabled]="!isEditable[i] ? true : null" 
               (change)="updateField($event, contact, 'primaryContact')" 
               class="form-control checkbox">
      </div>
      <div class="col-md-11">
        <label for="note">Note</label>
        <input [value]="contact.note" 
               [attr.disabled]="!isEditable[i] ? true : null" 
               (input)="updateField($event, contact, 'note')" 
               class="form-control">
      </div> 
      <div class="col-md-4">
        <button mat-button class="btn btn-edit" 
                *ngIf="!isEditable[i]" 
                (click)="setEditable(i, !isEditable[i])">Edit</button>
        <div class="row" *ngIf="isEditable[i]">
          <button mat-button class="btn btn-cancel col-md-5 mr-2" 
                  (click)="setEditable(i, !isEditable[i])">Cancel</button>
          <button mat-button class="btn btn-save col-md-5" 
                  (click)="saveContact(i)">Save</button>
        </div>
      </div>
    </div>
    <hr>
  </div>
</div>
<div mat-dialog-actions align="center">
  <button mat-button class="btn" style="background-color: var(--mk3red); color:white ;"
    (click)="onNoClick()">Close</button>
</div>
