import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
//import * as moment from 'moment-business-days';

@Injectable({
  providedIn: 'root'
})

export class FunctionsService {
  constructor(private datePipe: DatePipe) {
  }

  //Get the time value of 1955-05-05 for comparison of dates
  getTime5555() {
    let retVal: Number;
    retVal = new Date("1955-05-05T00:00:00").getTime();
    return retVal;
  }

  //Get the time value of the input date for comparison of dates
  getTimeValue(inDateTime: Date) {
    let retVal: Number;
    retVal = new Date(inDateTime).getTime();
    return retVal;
  }

  // getNextBusinessDay() {
  //   var nextBusinessDate = moment().businessAdd(1);
  //   return nextBusinessDate;
  // }

  //Return either the Finalised Date, Blank Value or "N/A" depending on the input dates
  getDateClosed(bldCompletion: Date, ctsCompletion: Date) {
    var dateBld: Date | null = null;
    var dateCts: Date | null = null;

    if (bldCompletion != null)
      dateBld = new Date(bldCompletion);

    if (ctsCompletion != null)
      dateCts = new Date(ctsCompletion);

    if ((bldCompletion == null || dateBld?.getTime() == this.getTime5555()) && (ctsCompletion == null || dateCts?.getTime() == this.getTime5555())) {
      {
        if (bldCompletion == null || ctsCompletion == null)
          return "";
        else
          return "N/A";
      }
    }
    else {
      let dateClosed = new Date();
      if (dateBld == null || dateCts == null) {
        return "";
      }
      else if (dateBld) {
        dateClosed = new Date(bldCompletion);
      }
      else if (dateCts != null) {
        dateClosed = new Date(ctsCompletion);
      }
      else {
        if (dateBld > dateCts)
          dateClosed = new Date(bldCompletion);
        else
          dateClosed = new Date(ctsCompletion);
      }
      if (dateClosed.getTime() == this.getTime5555()) {
        return "N/A";
      }
      return dateClosed;
    }
  }

  datePickerValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let forbidden = true;
      if (control.value) {
        const date: Date = control.value;
        const now = new Date();
        if (date.getDate() < now.setFullYear(now.getFullYear() - 2)) {
          forbidden = false;
        }
      }
      return forbidden ? { 'dateTooOld': true } : null;
    };
  }

  getDisplayName(nameString: string) {
    let result: string = nameString;
    if (nameString) {
      var nameArr = nameString.split(' ');
      let initial!: string;
      if (nameArr.length > 1)
        initial = nameArr.pop()?.charAt(0).toUpperCase()!;

      if (initial)
        result = nameArr[0] + ' ' + initial;
    }
    //console.log(nameString);
    return result ? result.trim() : '';

  }

  getCurrentTimestampFormatted()
  {
    return this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')?.toString();
  }

  encodeURIComponent(value: string|null) {
    if(value)
      return encodeURIComponent(value);
    else
      return '';
  }
}