export class PreliminaryInspectionDetails {
    preliminaryInspectionReportId: number=0;
    refNo!:number;
    caseNo!:string;
    recordedDateOfLoss : string|null = null;
    confirmedDateOfLoss : string|null = null;
    claimRecommendationOptionId: number =0;
    emergencyRepairId: number =0;
    specialistId:number=0;
    propertyManagementStatusId:number=0;
    attendedBy!:string;
    buildingPriceRangeId: number|null=null;
    contentsPriceRangeId: number|null=null;
    lossOfRentPriceRangeId: number|null=null;
    preliminaryInspectionDescriptionId: number|null=null;
    additionalInfo: string|null=null;
    inspectionDate!: string;
    isSent!:boolean;
    isActive =true;
  }