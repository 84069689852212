import { DatePipe, Location } from '@angular/common';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@app/_services/alert.service';
import { RestService } from '@app/_services/rest.service';
import { Observable } from 'rxjs';
import { PreliminaryInspectionDropDowns } from '@app/_models/preliminary-inspection-ddl';
import { PreliminaryInspectionDetails } from '@app/_models/preliminary-inspection-details';
import Swal from 'sweetalert2';
import { AlreadySent, Success } from '@app/_helpers/constants';
import { CommonHelper } from '@app/_helpers/common-helper';
import { AppComponent } from '@app/app.component';
import { groups } from '@app/_services/auth-config';
import { GlobalValues } from '@app/_services/global-values';

@Component({
  selector: 'app-preliminary-inspection',
  templateUrl: './preliminary-inspection.component.html',
  styleUrls: ['./preliminary-inspection.component.scss']
})
export class PreliminaryInspectionComponent implements OnInit {
  public form!: UntypedFormGroup;
  isYetToBeConfirmed : boolean=false;
  isLoading = true;
  refNo!: number;
  caseNo!: string;
  lastNameS!: string;
  firstContact!: string;
  lastContact!: string;
  isAlreadySent!: boolean;
  isHMAOrMk3ContractorsUser=true;
  isAssessorOrSAMOrManagement =false;
  preliminaryInspectionDetails: PreliminaryInspectionDetails = new PreliminaryInspectionDetails();
  preliminaryInspectionDDL!: PreliminaryInspectionDropDowns;
  selectedOption: number = 0;
  today = new Date().toISOString();
  items = [
    { id: 1, name: 'Option 1' },
    { id: 2, name: 'Option 2' },
    { id: 3, name: 'Option 3' }
  ];
  selectedItemId: number = 1;

  items1 = [
    { id: 1, name: 'Option 1' },
    { id: 2, name: 'Option 2' },
    { id: 3, name: 'Option 3' }
  ];

  constructor(
    public appComponent: AppComponent,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private restService: RestService,
    private commonHelper: CommonHelper,
    private datePipe: DatePipe,
    private globalValues: GlobalValues,
    private router: Router) {
      this.isHMAOrMk3ContractorsUser =this.globalValues.userGroupsArray.includes(groups.groupHMA) || this.globalValues.userGroupsArray.includes(groups.Mk3Contractors);
    this.isAssessorOrSAMOrManagement = this.globalValues.userGroupsArray.includes(groups.groupAssessors) || this.globalValues.userGroupsArray.includes(groups.groupStateAdminManagers) || this.globalValues.userGroupsArray.includes(groups.groupManagement)|| this.globalValues.userGroupsArray.includes(groups.groupIT);
    this.activatedRoute.params.subscribe(params => {
      this.refNo = params['refNo'];
      this.caseNo = params['caseNo'];
      this.lastNameS = params['lastNameS'];
      this.isAlreadySent = params['isAlreadySent'];
    });
  }

  ngOnInit(): void {
    this.isLoading=true;
    this.restService.getPreliminaryInspectionDropdowns().subscribe(x => {
      this.preliminaryInspectionDDL = x;
    this.restService.getPreLiminaryInspectionDetails(this.refNo).subscribe(x => {
      if (x)
      {
        this.preliminaryInspectionDetails = x;
        this.preliminaryInspectionDetails.confirmedDateOfLoss =this.preliminaryInspectionDetails.confirmedDateOfLoss==null && !this.preliminaryInspectionDetails.preliminaryInspectionReportId?this.preliminaryInspectionDetails.recordedDateOfLoss:this.preliminaryInspectionDetails.confirmedDateOfLoss;
        this.preliminaryInspectionDetails.recordedDateOfLoss= this.datePipe.transform(this.preliminaryInspectionDetails.recordedDateOfLoss, 'dd/MM/yyyy')!;
      }
        this.isLoading=false;
    })
  });

    this.form = new UntypedFormGroup({
      confirmedDateOfLoss: new UntypedFormControl(''),
      claimRecommendation: new UntypedFormControl('', [Validators.required]),
      emergencyWork: new UntypedFormControl('', Validators.required),
      attendedBy: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
      specialistReportRequired: new UntypedFormControl('', Validators.required),
      PropertyManagementDocuments: new UntypedFormControl('', [Validators.required]),
      buildingPriceRangeId: new UntypedFormControl('', Validators.required),
      contentsPriceRangeId: new UntypedFormControl('', Validators.required),
      lossOfRentPriceRangeId:new UntypedFormControl('', Validators.required),
      inspectionDate:new UntypedFormControl(new Date().toISOString, [Validators.required]),
      preliminaryInspectionDescriptionId: new UntypedFormControl('[]',),
      additionalInfo:new UntypedFormControl('', Validators.maxLength(2500))
    });

  }
  public onCancel = () => {
    this.location.back();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }
  savePreliminaryInspection(isSent =false) {
    this.isLoading=true;
    if (this.form.valid) {
      let preliminaryInspectionDetails = new PreliminaryInspectionDetails();

      preliminaryInspectionDetails.preliminaryInspectionReportId = this.preliminaryInspectionDetails.preliminaryInspectionReportId,
      preliminaryInspectionDetails.refNo = this.refNo,
      preliminaryInspectionDetails.caseNo = this.caseNo
      preliminaryInspectionDetails.confirmedDateOfLoss = this.preliminaryInspectionDetails.confirmedDateOfLoss!=null? this.convetDateToString(this.preliminaryInspectionDetails.confirmedDateOfLoss):null;
      preliminaryInspectionDetails.claimRecommendationOptionId = this.form.get('claimRecommendation')?.value,
      preliminaryInspectionDetails.emergencyRepairId = this.form.get('emergencyWork')?.value,
      preliminaryInspectionDetails.specialistId = this.form.get('specialistReportRequired')?.value,
      preliminaryInspectionDetails.propertyManagementStatusId = this.form.get('PropertyManagementDocuments')?.value,
      preliminaryInspectionDetails.attendedBy = this.form.get('attendedBy')?.value,
      preliminaryInspectionDetails.buildingPriceRangeId = this.form.get('buildingPriceRangeId')?.value,
      preliminaryInspectionDetails.contentsPriceRangeId = this.form.get('contentsPriceRangeId')?.value,
      preliminaryInspectionDetails.lossOfRentPriceRangeId = this.form.get('lossOfRentPriceRangeId')?.value,
      preliminaryInspectionDetails.preliminaryInspectionDescriptionId = this.form.get('preliminaryInspectionDescriptionId')?.value,
      preliminaryInspectionDetails.inspectionDate = this.convetDateToString(this.preliminaryInspectionDetails.inspectionDate);
      preliminaryInspectionDetails.additionalInfo= this.form.get('additionalInfo')?.value;

      this.restService.savePreliminaryInspectionDetails(preliminaryInspectionDetails).subscribe((response) => {
        let result = response.message;
        if(result == Success && isSent){
          this.sendPreliminaryInspection(preliminaryInspectionDetails);
        }
        else if(result == "Cannot add")
          {
            Swal.fire('Cannot add Preliminary Inspection Report! Already exist..', '', 'warning').then(()=>{
              this.isLoading=false;
              window.location.reload();
            })
          }
        else{
          Swal.fire('successfully saved!', '', 'success').then(()=>{
            this.isLoading=false;
          })
          ;
        }
      })
    }
  }
  sendPreliminaryInspection(preliminaryInspectionDetails: PreliminaryInspectionDetails) {
    this.restService.sendPreliminaryInspection(preliminaryInspectionDetails).subscribe((response) => {
      let result = response.message;
      if (result == Success) {
        Swal.fire('successfully triggered email sending!', '', 'success').then(() => {
          this.location.back();
        })
      }
      else if (result == AlreadySent) {
        Swal.fire('already email sent!', '', 'warning').then(() => {
          this.location.back();
        })
      }
    },
    (error)=>{
      Swal.fire('sendPreliminaryInspection : '+error, '', 'error').then(() => {
        this.location.back();
      })
    } )
  }

  convetDateToString(date:any):any
  {
    let formattedDate = '';

    if (typeof date === 'string')
    formattedDate=date;
    else if(date)
    {
      const year = date._i.year;
      const month =  date._i.month +1; 
      const day = date._i.date;
      formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    }
    return formattedDate;
  }
  getPreliminaryInspectionReport(reportId: number)
  {
    this.restService.getPreliminaryInspectionReportById(reportId).subscribe(x=>{
      let blobString= x;
      var blob = this.commonHelper.b64toBlob(blobString);
    var blobURL = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = blobURL;
    link.target = '_blank';
    link.click();
    });
  }
  onYetToBeConfirmedCheckboxChange(event:any)
  {
    this.isYetToBeConfirmed = event.target.checked;
    if(!this.isYetToBeConfirmed)
    {
      this.preliminaryInspectionDetails.confirmedDateOfLoss = this.datePipe.transform(this.preliminaryInspectionDetails.recordedDateOfLoss, 'yyyy-dd-MM')!;;
    }
    else
    {
      this.preliminaryInspectionDetails.confirmedDateOfLoss=null;
    }
  }
  onConfirmedDateOfLossChange()
  {
    this.isYetToBeConfirmed=this.preliminaryInspectionDetails.confirmedDateOfLoss==null;
  }

}
