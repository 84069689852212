<div class="app-container">
  <div class="loading-container" *ngIf="isLoading">
    <div class="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="red-icon" style="position: absolute;bottom: 42%">&nbsp;&nbsp;&nbsp;Loading...</div>
  </div>

  <div class="portal-header">
    <h5>Search for Open Claims</h5>
  </div>

  <!-- Filter Box -->
  <div class="search-box-container">
    <mat-form-field class="search-box-form-field">
      <mat-label>Search Claims by Keyword</mat-label>
      <input #search matInput type="text" onfocus="this.select()" (keyup)="onSearchKeyUp(search)" [value]="searchValue" [autoFocus]="true">
      <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="onClearClicked(search)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="table-res">
    <!-- Angular Material Table class="mat-elevation-z8 table-striped"-->
    <mat-table [dataSource]="dataSource" matSort matSortStart="asc" (matSortChange)="sortData($event)">
      <!-- <caption>Latest Claims</caption> -->

      <ng-container matColumnDef="refNo">
        <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>Mk3 Ref</th>
        <td mat-cell *matCellDef="let cases">{{cases.refNo}}</td>
      </ng-container>

      <ng-container matColumnDef="caseNo">
        <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>Case Number</th>
        <td mat-cell *matCellDef="let cases">{{cases.caseNo}}</td>
      </ng-container>

      <ng-container matColumnDef="lastNameS">
        <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>Last Names</th>
        <td mat-cell *matCellDef="let cases">{{cases.lastName}}</td>
      </ng-container>

      <ng-container matColumnDef="policyNo">
        <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>Policy Number</th>
        <td mat-cell *matCellDef="let cases">{{cases.policyNumber}}</td>
      </ng-container>

      <ng-container matColumnDef="recD">
        <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>Date Received</th>
        <td mat-cell *matCellDef="let cases">{{cases.receivedDate | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="consultant">
        <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>Consultant</th>
        <td mat-cell *matCellDef="let cases">{{cases.consultant}}</td>
      </ng-container>

      <ng-container matColumnDef="situationStreet">
        <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>Situation Street</th>
        <td mat-cell *matCellDef="let cases">{{cases.situationStreet}}</td>
      </ng-container>

      <ng-container matColumnDef="situationSuburb">
        <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>Situation Suburb</th>
        <td mat-cell *matCellDef="let cases">{{cases.situationSuburb}}</td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
        <td mat-cell *matCellDef="let cases">{{cases.state}}</td>
      </ng-container>

      <ng-container matColumnDef="postCode">
        <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>Postcode</th>
        <td mat-cell *matCellDef="let cases">{{cases.postCode}}</td>
      </ng-container>

      <ng-container matColumnDef="endToEnd">
        <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>End to End</th>
        <td mat-cell *matCellDef="let cases">
          <ng-container *ngIf="cases.endToEnd==true">Yes</ng-container>
          <ng-container *ngIf="cases.endToEnd==false">No</ng-container>
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="status">
        <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>Claim Status</th>
        <td mat-cell *matCellDef="let cases">
          <ng-container>{{getClaimStatus(cases.bldCompletion, cases.ctsCompletion)}}</ng-container>
        </td>
      </ng-container> -->

      <ng-container matColumnDef="status">
        <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>Claim Status</th>
        <td mat-cell *matCellDef="let cases">
          <!-- <ng-container *ngIf="cases.statusClaimTypeID==1 || cases.statusClaimTypeID==3">Open</ng-container>
          <ng-container *ngIf="cases.statusClaimTypeID==2 || cases.statusClaimTypeID==4">Closed</ng-container> -->
          <ng-container>{{getClaimStatus(cases.statusClaimTypeID)}}</ng-container>
        </td>
      </ng-container> 

      <ng-container matColumnDef="originalStatus">
        <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header>Progress Status</th>
        <td mat-cell *matCellDef="let cases">{{cases.originalStatus}}</td>
      </ng-container>

      <ng-container matColumnDef="formerCaseNo" style="display: none;">
        <th class="wc-2" mat-header-cell *matHeaderCellDef mat-sort-header style="display: none;">Former Case No</th>
        <td mat-cell *matCellDef="let cases" style="display: none;">{{cases.formerCaseNo}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="'/case-details/'+ row.refNo"
        class="row-hover mat-row"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9999">
          <div *ngIf="search.value.length >= 4">
            <b>Either the claim you are searching has been archived or your search text is not related to any
              claim.<br />
              Please contact Mk3 directly for access to this claim</b>
          </div>
        </td>
    </mat-table>

    <!-- Paginator -->
    <div class="mat-paginator" *ngIf="dataSource && dataSource.data">
      <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, dataSource.data.length]"
        showFirstLastButtons class="mat-paginator">
      </mat-paginator>
    </div>
  </div>
  <!-- Reload Button -->
  <div class="center-button-container" style="padding: 0px">
    <button mat-raised-button class="clearbutton" (click)=onClearClicked(search)>Clear</button>
  </div>
</div>
