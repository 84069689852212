<div class="app-container">

  <div class="loading-container" *ngIf="isLoading">
    <div class="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div class="portal-header">
    <h5 style="text-align: center;">End to End Escape of Liquid Claims</h5>
  </div>

  <div class="vertical-form-field">
    <div>
      <mat-form-field *ngFor="let filter of filterSelectObj; let i = index" appearance="fill" class="no-label-field">
        <div *ngIf="i == 0">
          <mat-select [formControl]="stateFilter">
            <mat-option value="">Filter by {{filter.name}}</mat-option>
            <mat-option [value]="item" *ngFor="let item of filter.options">{{item}}</mat-option>
          </mat-select>
        </div>
        <div *ngIf="i == 1">
          <mat-select [formControl]="claimStatusFilter">
            <mat-option value="">Filter by {{filter.name}}</mat-option>
            <mat-option [value]="item" *ngFor="let item of filter.options">{{item}}</mat-option>
          </mat-select>
        </div>
      </mat-form-field>
      &nbsp;
      <button class="btn btn-sm btn-danger" (click)="clearFilters()">Clear Filters</button>
      &nbsp;
      <button style="width: fit-content;" class="btn btn-sm btn-danger"
        (click)="exporter.exportTable('xlsx',{fileName:'Mk3 EOL Claims ' + functionsService.getCurrentTimestampFormatted()})">Export
        to Excel</button>
    </div>
  </div>

  <div class="main-table-container">
    <table mat-table matTableExporter [dataSource]="dataSource" matSort matSortStart="asc" #exporter="matTableExporter">
      <caption>End to End Escape of Liquid Claims</caption>

      <ng-container matColumnDef="refNo">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Mk3 Ref</th>
        <td mat-cell *matCellDef="let cases">{{cases.refNo}}</td>
      </ng-container>

      <ng-container matColumnDef="caseNo">
        <th class="w-20" mat-header-cell *matHeaderCellDef mat-sort-header>Case Number</th>
        <td mat-cell *matCellDef="let cases">{{cases.caseNo}}</td>
      </ng-container>

      <ng-container matColumnDef="lastNameS">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Insured</th>
        <td mat-cell *matCellDef="let cases">{{cases.lastNameS}}</td>
      </ng-container>

      <ng-container matColumnDef="recD">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Date Received</th>
        <td mat-cell *matCellDef="let cases">{{cases.recD | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="claimStatus">
        <th class="w-20" mat-header-cell *matHeaderCellDef mat-sort-header>Claim Status</th>
        <td mat-cell *matCellDef="let cases">
          <!-- <ng-container>{{cases.claimStatus}}</ng-container> -->
          <ng-container>{{getClaimStatus(cases.statusClaimTypeID)}}</ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="inspDate">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Last Assessment</th>
        <td mat-cell *matCellDef="let cases">
          <ng-container
            *ngIf="functionsService.getTimeValue(cases.inspDate) == functionsService.getTime5555(); else elseValidDate">
            N/A
          </ng-container>
          <ng-template #elseValidDate>
            {{cases.inspDate | date: 'dd/MM/yyyy'}}
          </ng-template>
        </td>
      </ng-container>


      <ng-container matColumnDef="lastContact">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Last Contact</th>
        <td mat-cell *matCellDef="let cases">
          <ng-container
            *ngIf="functionsService.getTimeValue(cases.lastContact) == functionsService.getTime5555(); else elseValidDate">
            N/A
          </ng-container>
          <ng-template #elseValidDate>
            {{cases.lastContact | date: 'dd/MM/yyyy'}}
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="contactDays">
        <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Contact Days</th>
        <td mat-cell *matCellDef="let cases">
          <ng-container *ngIf="cases.claimStatus == 'Finalised'; else elseContactDays">
            N/A
          </ng-container>
          <ng-template #elseContactDays>
            {{cases.contactDays}}
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="largeLoss">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Large Loss</th>
        <!-- <td mat-cell *matCellDef="let cases">{{cases.largeLoss}}</td> -->
        <td mat-cell *matCellDef="let cases">
          <mat-cell style="border: 0px;" *ngIf="cases.largeLoss==-1">Yes</mat-cell>
          <mat-cell style="border: 0px;" *ngIf="cases.largeLoss==0">No</mat-cell>
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th style="width: 3%;text-align: center;" mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
        <td mat-cell *matCellDef="let cases">{{cases.state}}</td>
      </ng-container>

      <ng-container matColumnDef="postCode">
        <th style="width: 3%;text-align: center;" mat-header-cell *matHeaderCellDef mat-sort-header>Postcode</th>
        <td mat-cell *matCellDef="let cases">{{cases.postCode}}</td>
      </ng-container>

      <ng-container matColumnDef="sentReport">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Report Sent</th>
        <td mat-cell *matCellDef="let cases">
          <ng-container
            *ngIf="functionsService.getTimeValue(cases.sentReport) == functionsService.getTime5555(); else elseValidDate">
            N/A
          </ng-container>
          <ng-template #elseValidDate>
            {{cases.sentReport | date: 'dd/MM/yyyy'}}
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="xsDateRecD">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Excess Received</th>
        <td mat-cell *matCellDef="let cases">
          <ng-container
            *ngIf="functionsService.getTimeValue(cases.xsDateRecD) == functionsService.getTime5555(); else elseValidDate">
            N/A
          </ng-container>
          <ng-template #elseValidDate>
            {{cases.xsDateRecD | date: 'dd/MM/yyyy'}}
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="ecdEstimatedCompletionDate">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Expected Finalisation</th>
        <td mat-cell *matCellDef="let cases">
          <ng-container
            *ngIf="functionsService.getTimeValue(cases.ecdEstimatedCompletionDate) == functionsService.getTime5555(); else elseValidDate">
            N/A
          </ng-container>
          <ng-template #elseValidDate>
            {{cases.ecdEstimatedCompletionDate | date: 'dd/MM/yyyy'}}
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="statusModified">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let cases">{{cases.statusModified}}</td>
      </ng-container>

      <ng-container matColumnDef="bldEstimate">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Building Estimate</th>
        <td mat-cell *matCellDef="let cases">{{cases.bldEstimate|currency:'USD':true:'1.0-3'}}</td>
      </ng-container>

      <ng-container matColumnDef="ctsEstimate">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Contents Estimate</th>
        <td mat-cell *matCellDef="let cases">{{cases.ctsEstimate|currency:'USD':true:'1.0-3'}}</td>
      </ng-container>

      <ng-container matColumnDef="bldAmtSettled">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Building Settled</th>
        <td mat-cell *matCellDef="let cases">{{cases.bldAmtSettled|currency:'USD':true:'1.0-3'}}</td>
      </ng-container>

      <ng-container matColumnDef="ctsAmtSettled">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Content Settled</th>
        <td mat-cell *matCellDef="let cases">{{cases.ctsAmtSettled|currency:'USD':true:'1.0-3'}}</td>
      </ng-container>

      <ng-container matColumnDef="emergencyRepair">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Emergency Repair</th>
        <!-- <td mat-cell *matCellDef="let cases">{{cases.emergencyRepairModified}}</td> -->
        <td mat-cell *matCellDef="let cases">
          <mat-cell style="border: 0px;" *ngIf="cases.emergencyRepair=='No'">No</mat-cell>
          <mat-cell style="border: 0px;" *ngIf="cases.emergencyRepair!='No'">Yes</mat-cell>
        </td>
      </ng-container>

      <ng-container matColumnDef="tempAccom">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Temp Accom</th>
        <td mat-cell *matCellDef="let cases">{{cases.tempAccom}}</td>
      </ng-container>

      <ng-container matColumnDef="dateFinalised">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Date Finalised</th>
        <td mat-cell *matCellDef="let cases">
          <ng-container
            *ngIf="functionsService.getTimeValue(cases.dateFinalised) == functionsService.getTime5555(); else elseValidFinalisedDate">
            N/A
          </ng-container>
          <ng-template #elseValidFinalisedDate>
            {{cases.dateFinalised | date: 'dd/MM/yyyy'}}
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="holdContact">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Hold Contact</th>
        <td mat-cell *matCellDef="let cases">
          <mat-cell style="border: 0px;" *ngIf="cases.holdContact==-1">Yes</mat-cell>
          <mat-cell style="border: 0px;" *ngIf="cases.holdContact==0">No</mat-cell>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="'/case-details/'+ row.refNo"
        class="row-hover mat-row"></tr>
    </table>

    <div class="mat-paginator">
      <mat-paginator #paginator [pageSize]="12" [pageSizeOptions]="[12, 50, 100, dataSource.data.length]"
        showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
