import { Component, ViewChild } from '@angular/core';
import { AccountService, AlertService } from '.';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PublicClientApplication } from '@azure/msal-browser';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InputRefNoDialog } from './_components/home';
import { Router } from '@angular/router';
import { groups } from './_services/auth-config';
import { RestService } from './_services/rest.service';
import { Employee } from './_models/employee.model';
import { Observable } from 'rxjs';


export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',

  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};


//The app component is the root component of the application, it defines the root tag of the app as <app></app> with the selector property of the @Component() decorator.
//It subscribes to the user observable of the account service so it can reactively show/hide the main navigation bar when the user logs in/out of the application.
//I didn't worry about unsubscribing from the observable here because it's the root component of the application and will only be destroyed when the angular app is closed.
//The logout() method is called from the logout link in the main nav bar above to log the user out and redirect them to the login pag
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    MatDatepickerModule,
    MatNativeDateModule
  ]
})

export class AppComponent {
  title = 'Mk3 Portal';
  isIframe = false;
  inputDialogRef!: MatDialogRef<InputRefNoDialog>;
  userType: number = 0;
  userGroupsArray: string[] = [];
  encryptedConsultantName: string = '';
  
  @ViewChild('childModal', { static: false })
  public childModal!: ModalDirective;

  constructor(
    public accountService: AccountService, 
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public dialog: MatDialog, private alertService: AlertService,
    private restService: RestService,

    ) {

     }

  async ngOnInit() {
   

    const pca = this.msalService.instance as PublicClientApplication;
    await pca.initialize();
    this.isIframe = window !== window.parent && !window.opener;
    this.msalService.instance.handleRedirectPromise().then(
      res => {
        if (res != null && res.account !== null) {
          this.msalService.instance.setActiveAccount(res.account);
        }
    this.getAllAccountsForUser();
    this.getEncryptedConsultantName(this.getName()).then((data) => {
      this.encryptedConsultantName = data!.encryptedValue;
    });
      }
    ).catch(err => {
      console.error(err);
    });
  }

  public async openSearchDialog(isByRefNo: boolean) {
    this.alertService.clear();
    this.inputDialogRef = this.dialog.open(InputRefNoDialog, {
      minWidth: '400px',
      maxWidth: '1000px',
      data: { isByRefNo: isByRefNo }
    });
  }

  async getAllAccountsForUser(): Promise<void> {
    const currentAccounts = this.msalService.instance.getAllAccounts();

    (currentAccounts[0].idTokenClaims as any).groups.forEach((group: any) => {
      this.userGroupsArray.push(group);
    });
  }

  isLoggedIn(): boolean {
    return (this.msalService.instance.getActiveAccount() != null) ? true : false;
  }

  login() {
    this.msalService.loginRedirect();
  }

  logout() {
    this.msalService.logout();
  }

  public getName(): string {
    return this.msalService.instance.getActiveAccount()!.name!;
  }

  public getEmail(): string {
    return this.msalService.instance.getActiveAccount()!.username!;
  }

  public getUserDetails(): Observable<Employee> {
    return this.restService.getUserDetailsByEmail(this.msalService.instance.getActiveAccount()!.username!) 
  }
  
  public getEncryptedConsultantName(consultant: string): Promise<any>{
    //let encryptedConsultantName: string = '';
    return this.restService.getEncryptedQueryParam(consultant).pipe().toPromise();  
    //return encryptedConsultantName;   
  }  
  
  setUserType(groupIDs: string[]) {
    try {
      // if (groupIDs.includes(groups.groupUAT)) {
      //   this.userType = 6;//UAT Testing
      //   return;
      // }
      if (groupIDs.includes(groups.groupIT)) {
        this.userType = 1;//IT
        return;
      }
      if (groupIDs.includes(groups.groupManagement)) {
        this.userType = 2;//Management
        return;
      }
      if (groupIDs.includes(groups.groupMk3Staff)) {
        this.userType = 3;//Mk3Staff
        return;
      }
      if (groupIDs.includes(groups.groupHMA)) {
        this.userType = 4;//HMA
        return;
      }
    }
    catch (error: any) {
    }
  }

  public getUserType(): number {
    return this.userType;
  }

}

