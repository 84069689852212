export class ClaimContact {
  contactID!: number;
  refNo!: number;
  name: string = '';
  claimContactRoleID!: number;
  phone: string = '';
  emailAddress: string = '';
  note: string = '';
  primaryContact: boolean = false;
  LastModifiedByEmployeeID!: number;
}

export class vClaimContact {
  contactID!: number;
  refNo!: number;
  name: string = '';
  claimContactRole: string = '';
  phone: string = '';
  emailAddress: string = '';
  note: string = '';
  primaryContact: boolean = false;
}
