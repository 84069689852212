import { DatePipe, Time } from '@angular/common';
import { Injectable } from '@angular/core';
//import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CommonHelper {
    constructor() {}
    b64toBlob(b64Data: string) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
    
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);
    
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
    
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
    
        const blob = new Blob(byteArrays, { type: 'application/pdf' });
        return blob;
      }

}