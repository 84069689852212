import { Component, Inject, OnInit, ChangeDetectorRef  } from '@angular/core';
import {  MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClaimContact, vClaimContact } from '@app/_models/claimContact';
// import { DialogData } from '@app/_models/dialogData.model';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from '@app/_services/rest.service';
import { UntypedFormBuilder, UntypedFormGroup, FormArray } from '@angular/forms';
import { AlertService } from '@app/_services/alert.service';
import { ClaimContactRole } from '@app/_models/claimContactRole.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailComponent implements OnInit {
  contactForm!: UntypedFormGroup;
  isEditable: boolean[] = [];
  isLoading: boolean = false;
  claimContact: ClaimContact[] = [];
  newClaimContact: ClaimContact[] = [];
  claimContactRoles: ClaimContactRole[] = [];
  previousPrimaryIndex: number | null = null;
  isAddNewContact: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ContactDetailComponent>, 
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private restService: RestService,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
    ){
      this.claimContact = data.claimContact;
      this.isAddNewContact = data.isAddNewContact? data.isAddNewContact : false;
      this.alertService.clear();
    }

    ngOnInit(): void {
      console.log(this.isAddNewContact);
      if(this.isAddNewContact){
        this.addNewContact();
      }

      this.fetchRoles();
      this.cdr.detectChanges();
      this.contactForm = this.fb.group({
        contacts: this.fb.array([]), 
      });
    }
    
    fetchRoles() {
      this.restService.getClaimContactsRole().subscribe(
        roles => {
          this.claimContactRoles = roles;
        },
        error => {
          console.error('Fetch roles failed', error);
        }
      );
    }
    getRoleNameById(id: number): string {
      const role = this.claimContactRoles.find(role => role.contactTypeID === id);
      return role ? role.contactPerson : 'Unknown';
    }
    
    addNewContact(): void {
      // Create a new contact object with default values
      let newContact = {
        refno: this.data.claimContact[0].refNo,
        name: '',
        phone: '',
        emailAddress: '',
        claimContactRoleID: 0,
        note: '',
        // ... other properties with default values
        primaryContact: false // This ensures the checkbox is unchecked
      };
      // Add the new contact to your data array
      this.data.claimContact.push(newContact);
      //revese order of array to make sure new contact is at the top
      this.data.claimContact.reverse();
      this.setEditable(0, true);
    }
    validateAndSetPhone(event: Event, contact: any): void {
      // Cast the event target to an HTMLInputElement to access the value
      let inputElement = event.target as HTMLInputElement;
      
      // Replace any non-digit characters with an empty string
      inputElement.value = inputElement.value.replace(/\D/g, '');
      
      // Now, update the contact with the cleaned value
      contact.phone = inputElement.value;
      
      // Call your update method if necessary
      this.updateField({ value: contact.phone }, contact, 'phone');
    }
    
    
    updateField(event: any, contact: any, field: string) {
      if (field === 'primaryContact' && contact.primaryContact && !event.target.checked) {
        event.preventDefault();
        return;
      }
      contact[field] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      if (field === 'primaryContact') {
        if (contact.primaryContact) {
          console.log('This contact is now set as primary.');
        } else {
          console.log('This contact is no longer set as primary.');
        }
      }

      if (field === 'claimContactRoleID') {
        contact[field] = Number(event.target.value);
      } else if (field === 'primaryContact') {
        contact[field] = event.target.checked;
      } else {
        contact[field] = event.target.value;
      }
    }

    setEditable(index: number, editable: boolean) {
      this.isEditable[index] = editable;
    }
    
    saveContact(index: number): void {

      let isValid =  this.validateContact(index);

      if(isValid)
        {
          this.isLoading = true;

      if(this.isAddNewContact && index == 0){
        const newContact: ClaimContact = this.claimContact[index];
        newContact.name = this.claimContact[index].name;
        newContact.claimContactRoleID = this.claimContact[index].claimContactRoleID;
        newContact.phone = this.claimContact[index].phone;
        newContact.emailAddress = this.claimContact[index].emailAddress;
        newContact.note = this.claimContact[index].note;
        newContact.primaryContact = this.claimContact[index].primaryContact;
         
        console.log(newContact)
        if (newContact.primaryContact == true) {
          const patchDocument = [
            { op: "replace", path: "/primaryContact", value: false }
          ];
          this.restService.patchClaimContact(this.claimContact[this.claimContact.length - 1].contactID, patchDocument).subscribe(
            () =>{
              Swal.fire('primary contact updated successfully!', '', 'success').then(()=>{
              this.setEditable(index, false);
              this.isLoading = false
              });
            },
            error => {
              console.error('Update failed', error);
              this.isLoading = false
            });
        }
        this.restService.postClaimContact(newContact, this.data.refNo).subscribe(
          () => {
            console.log('Created successfully');
            this.setEditable(index, false);
            this.isLoading = false
          },
          error => {
            console.error('Create failed', error);
            this.isLoading = false
          }
        );

      }
      else{
        const updatedContact: ClaimContact = this.claimContact[index];

      // Index 0 is always the primary contact
      if (updatedContact.primaryContact) {
        if (this.claimContact[0].contactID != this.claimContact[index].contactID) {
          const patchDocument = [
            { op: "replace", path: "/primaryContact", value: false }
          ];
          this.restService.patchClaimContact(this.claimContact[0].contactID, patchDocument).subscribe(
            () =>{+
              Swal.fire('primary contact updated successfully!', '', 'success').then(()=>{
              this.setEditable(index, false);
              this.isLoading = false
              });
            },
            error => {
              console.error('Update failed', error);
              this.isLoading = false
            });
        }
        updatedContact.LastModifiedByEmployeeID = this.data.userDetail.employeeId;
        // Update the new primary contact
        this.restService.putClaimContact(updatedContact).subscribe(
          () => {
            Swal.fire('successfully saved!', '', 'success').then(()=>{
              this.previousPrimaryIndex = index;  // Set the new primary index
              this.setEditable(index, false);
              this.isLoading = false
              this.isLoading=false;
            })
          },
          error => {
            console.error('Update failed', error);
            this.isLoading = false
          }
        );
      } else if (this.claimContact[0].primaryContact == false) {
        return;
      }
      else {
        // Normal update
        updatedContact.LastModifiedByEmployeeID = this.data.userDetail.employeeId;
        updatedContact.claimContactRoleID = this.claimContact[index].claimContactRoleID;
        this.restService.putClaimContact(updatedContact).subscribe(
          () => {
            Swal.fire('successfully saved!', '', 'success').then(()=>{
            this.setEditable(index, false);
            this.isLoading = false
            });
          },
          error => {
            console.error('Update failed', error);
            this.isLoading = false
          }
        );
      }
      }
    }
    }


    validateContact(index: number): boolean{
      let isValid = true;

      if(!this.claimContact[index].claimContactRoleID)
        {
          isValid=false;
        }

        return isValid;

    }
    
    currentPrimaryIndex: number | null = null;

    setPrimaryContact(index: number): void {
      if (this.currentPrimaryIndex !== null) {
        this.claimContact[this.currentPrimaryIndex].primaryContact = false;
      }
      
      this.claimContact[index].primaryContact = true;
      this.currentPrimaryIndex = index;
    }


    
    onNoClick(): void {
      this.dialogRef.close();
      if(this.isAddNewContact){
        this.data.claimContact.reverse()
        this.data.claimContact.pop();
      }

    }
  
    closeDialog() {
      this.dialogRef.close();
      if(this.isAddNewContact){
        this.data.claimContact.reverse()
        this.data.claimContact.pop();
      }
    }
   
}
