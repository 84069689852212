import { Injectable, Component , Inject} from '@angular/core';
import {  MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { MatTableDataSource } from '@angular/material/table';
import { DialogData } from '@app/_models/dialogData.model';

@Component({
    selector: 'note-details-dialog',
    templateUrl: './note-details-dialog.html',
  })
  
  export class NoteDetailsDialogComponent {
    constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData, 
      public dialogRef: MatDialogRef<NoteDetailsDialogComponent>, 
      public dialog: MatDialog,) {
    }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
    closeDialog() {
      this.dialogRef.close();
    }
  }
