import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CaseComponent } from './_components/case/case.component';
import { CaseDetailComponent } from './_components/case-detail/case-detail.component';

import { HomeComponent } from './_components/home';
import { AllOpenClaimsComponent } from './_components/reports/all-open-claims/all-open-claims.component';
import { OpenClaimsListComponent } from './_components/reports/open-claims-list/open-claims-list.component';
import { MsalGuard } from '@azure/msal-angular';
import { NewNoteComponent } from './_components/case-detail/new-note/new-note.component';
import { PreliminaryInspectionComponent } from './_components/case-detail/preliminary-inspection/preliminary-inspection.component';
import { ManagementDashComponent } from './_components/reports/management-dash/management-dash.component';
import { ClaimReceivedSummaryComponent } from './_components/reports/claim-received-summary/claim-received-summary.component';
import { TradeComponent } from './_components/case-detail/trade/trade.component';


const routes: Routes = [

  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'cases',
    component: CaseComponent,
    canActivate: [MsalGuard],
    data: { title: 'Case List' }
  },
  {
    path: 'case-details/:refNo',
    component: CaseDetailComponent,
    canActivate: [MsalGuard],
    data: { title: 'Claim Details' }
  },
  {
    path: 'case-detail/new-note/:refNo/:caseNo/:lastNameS/:firstContact/:lastContact',
    component: NewNoteComponent,
    canActivate: [MsalGuard],
    data: { title: 'Add New Note' }
  },
  {
    path: 'reports/all-open-claims',
    component: AllOpenClaimsComponent,
    canActivate: [MsalGuard],
    data: { title: 'My Open Claims Details' }
  },
  {
    path: 'reports/open-claims-list',
    component: OpenClaimsListComponent,
    canActivate: [MsalGuard],
    data: { title: 'My Open Claims List' }
  },
  {
    path: 'preliminary-inspection/:refNo/:caseNo/:lastNameS/:isAlreadySent',
    component: PreliminaryInspectionComponent,
    canActivate: [MsalGuard],
    data: { title: 'Preliminary Inspection Report' }
  },
  {
    path: 'trade/:refNo',
    component: TradeComponent,
    canActivate: [MsalGuard],
    data: { title: 'Trades' }
  },
  {
    path: 'reports/claim-received-summary',
    component: ClaimReceivedSummaryComponent,
    canActivate: [MsalGuard],
    data: { title: 'Claim Received Summary' }
  },
  {
    path: 'reports/management-dash',
    component: ManagementDashComponent,
    canActivate: [MsalGuard],
    data: { title: 'Management Dashboard' }
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
