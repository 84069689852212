import { Component, Inject, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { Case } from '@app/_models/case';
import { AccountService, AlertService } from '@app/index';
import { RestService } from '@app/_services/rest.service';
import { first } from 'rxjs/operators';

// /The home component defines an angular component that gets the current logged in user from the account service
//and makes it available to the template via the user object property
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent {
    refNo: number = 0;
    inputDialogRef!: MatDialogRef<InputRefNoDialog>;

    constructor(public accountService: AccountService, public appComponent: AppComponent,
        public dialog: MatDialog, private alertService: AlertService,
        @Optional() public dialogRef: MatDialogRef<InputRefNoDialog>,
        @Inject(MAT_DIALOG_DATA) public data: { refNo: number, isByRefNo: boolean }) {
    }

    public openSearchDialog(isByRefNo: boolean) {
        this.alertService.clear();
        this.inputDialogRef = this.dialog.open(InputRefNoDialog, {
            minWidth: '400px',
            maxWidth: '1000px',
            data: { isByRefNo: isByRefNo }
        });
    }
}
@Component({
    selector: 'input-refno-dialog',
    templateUrl: 'input-refno-dialog.html',
})
export class InputRefNoDialog {
    public form!: UntypedFormGroup;
    isLoading = true;
    case!: Case;
    refNo: number = 0;
    caseNo!: string;
    isByRefNo!: boolean;

    constructor(private restService: RestService, private alertService: AlertService,
        public dialogRef: MatDialogRef<InputRefNoDialog>, @Inject(MAT_DIALOG_DATA) public dialogData: any) {
        this.form = new UntypedFormGroup({
            refNo: new UntypedFormControl('', [Validators.maxLength(5), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            caseNo: new UntypedFormControl('')

        });
        this.isByRefNo = this.dialogData.isByRefNo;
    }

    async fetchData(value: string, isByRefNo: boolean): Promise<boolean> {
        //Get case details
        this.isLoading = true;
        let success: boolean = false;
        if (isByRefNo) {
            await this.restService.isValidCaseID(Number(value));
        }
        else {
            await this.restService.getClaimByClaimNumber(value);
        }
        this.dialogRef.close(Number(value));
        return success;
    }

    /*async openClaimDetails() {
        this.dialogData.refNo = this.form.controls['refNo'].value;
        this.dialogData.caseNo = this.form.controls['caseNo'].value;

        if (this.form.invalid) {
            return;
        }
        if (this.isByRefNo)
            await this.fetchData(this.form.controls['refNo'].value, this.isByRefNo);
        else {
            let caseNo: string = this.form.controls['caseNo'].value;

            if (caseNo.substring(0, 2) == '1/')
                caseNo = caseNo.replace('1/', '1');

            if (caseNo.indexOf(' ') > 0)
                caseNo = caseNo.substring(0, caseNo.indexOf(' '));

            this.dialogData.caseNo = caseNo;
            await this.fetchData(caseNo, this.isByRefNo);
        }
    }*/

    public async onSubmit() {
        this.dialogData.refNo = this.form.controls['refNo'].value;
        this.dialogData.caseNo = this.form.controls['caseNo'].value;

        if (this.form.invalid) {
            return;
        }
        if (this.isByRefNo)
            await this.fetchData(this.form.controls['refNo'].value, this.isByRefNo);
        else {
            let caseNo: string = this.form.controls['caseNo'].value;

            if (caseNo.substring(0, 2) == '1/')
                caseNo = caseNo.replace('1/', '1');

            if (caseNo.indexOf(' ') > 0)
                caseNo = caseNo.substring(0, caseNo.indexOf(' '));

            this.dialogData.caseNo = caseNo;
            await this.fetchData(caseNo, this.isByRefNo);
        }
    }

    public onCancel = () => {
        this.dialogRef.close('cancel');
        this.alertService.clear();
        return;
    }

    public hasError = (controlName: string, errorName: string) => {
        return this.form.controls[controlName].hasError(errorName);
    }

    public findInvalidControls(): string[] {
        const invalid = [];
        const controls = this.form.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push('Invalid Control: ' + name);
            }
        }
        return invalid;
    }
}