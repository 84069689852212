import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Note } from '@app/_models/note';
import { RestService } from '@app/_services/rest.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { AlertService } from '@app/index';
import { MsalService } from '@azure/msal-angular';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-note',
  templateUrl: './new-note.component.html',
  styleUrls: ['./new-note.component.scss']
})
export class NewNoteComponent implements OnInit {
  public form!: UntypedFormGroup;
  noteDescription!: string;
  refNo!: number;
  caseNo!: string;
  lastNameS!: string;
  firstContactDate: Date | null = null;
  lastContactDate: Date | null = null;
  loading = false;
  submitted = false;
  newNote!: Note;
  result!: string;
  noteTypes!: Observable<any>;
  @Input() max: any;
  today = new Date();

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private restService: RestService,
    private alertService: AlertService,
    private msalService: MsalService,
    private datePipe: DatePipe,
    private router: Router) {
    this.activatedRoute.params.subscribe(params => {
      this.refNo = params['refNo'];
      this.caseNo = params['caseNo'];
      this.lastNameS = params['lastNameS'];
      this.firstContactDate = params['firstContact'] != 'null' ? new Date(this.datePipe.transform(params['firstContact'], 'yyyy-MM-dd')?.toString()!) : null;
      this.lastContactDate = params['lastContact'] != 'null' ? new Date(this.datePipe.transform(params['lastContact'], 'yyyy-MM-dd')?.toString()!) : null
    });
  }

  ngOnInit(): void {
    this.noteTypes = this.restService.getClaimNoteTypes();
    this.form = new UntypedFormGroup({
      noteDescription: new UntypedFormControl('', [Validators.required, Validators.maxLength(2500)]),
      noteTypeId: new UntypedFormControl(4),
      noteDate: new UntypedFormControl(new Date(), [Validators.required])
    });
  }

  public onCancel = () => {
    this.location.back();
  }

  public createNote = (noteFormValue: any) => {
    if (this.form.valid) {
      if (this.form.controls.noteDescription.value.trim() != '') {
        Swal.fire({
          title: 'Are you sure you want to add the New Note?',
          icon: 'question',
          showDenyButton: true,
          confirmButtonText: `Yes`,
          denyButtonText: `No`,
          confirmButtonColor: '#007bff',
          denyButtonColor: '#dc3545',
          focusDeny: true,
          width: 450,
          padding: '1em',
          heightAuto: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.submitted = true;
            this.alertService.clear();

            // stop here if form is invalid
            if (this.form.invalid) {
              return;
            }

            const userFullName = this.msalService.instance.getActiveAccount()!.name!;
            this.newNote = {
              refNo: this.refNo,
              date: this.datePipe.transform(this.form.controls.noteDate.value, 'yyyy-MM-dd')?.toString()!,
              addedBy: userFullName,
              initiatedBy: "Mk3 Staff",
              noteTypeId: this.form.controls.noteTypeId.value,
              subTypeId: 6,
              reasonId: 32,
              notes: this.form.controls.noteDescription.value,
              private: this.form.controls.noteTypeId.value === 4 ? -1 : 0,//-1 = Private, 0 = Public
              timeStamp: null,
              followUpDate: null,
              followUpUser: null,
              followUpComplete: null,
              clientId: null
            };

            //Update Last Contact Date
            if (this.form.controls.noteTypeId.value == 2 && (this.lastContactDate === null || isNaN(Date.parse(this.lastContactDate.toString())) || this.form.controls.noteDate.value > this.lastContactDate)) {
              const body = [{
                op: "replace",
                path: "/lastContact",
                value: this.datePipe.transform(this.form.controls.noteDate.value, 'yyyy-MM-dd')?.toString()!
              }];
              this.restService.updateLastContactDate(this.refNo, JSON.stringify(body));
            }

            //Update First Contact Date
            if (this.form.controls.noteTypeId.value == 2 && (this.firstContactDate === null || Number.isNaN(new Date(this.firstContactDate).getTime()))) {
              const body = [{
                op: "replace",
                path: "/firstContact",
                value: this.datePipe.transform(this.form.controls.noteDate.value, 'yyyy-MM-dd')?.toString()!
              }];
              this.restService.updateFirstContactDate(this.refNo, JSON.stringify(body));
            }

            this.loading = true;
            this.restService.addNewNote(this.newNote)
              .pipe(first())
              .subscribe({
                next: () => {
                  Swal.fire('New note has been added successfully!', '', 'success')
                  this.router.navigate(['../case-details/' + this.newNote.refNo]).then(() => {
                  })
                },
                error: (error: string) => {
                  this.loading = false;
                  this.alertService.error(error);
                }
              });
          } else if (result.isDenied) {
            this.loading = false;
          }
        })
      }
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }
}
