import { Injectable } from "@angular/core"; 
import { MsalService } from '@azure/msal-angular';
import { Subject } from "rxjs";

@Injectable({
    providedIn:'root'
})

export class GlobalValues{

    isDescriptionDDLCloseOnSelect:boolean = true;

    userInGroupList = this.msalService.instance.getAllAccounts()[0].idTokenClaims;
    userEmailId = this.msalService.instance.getActiveAccount()!.username! ;
    userName = this.msalService.instance.getActiveAccount()!.name!;
    userGroupsArray : Array<string> = [];
    accessToken : any;
    globalVariableSubject  = new Subject<any>();
    

constructor(private msalService: MsalService 
  ){
    const currentAccounts = this.msalService.instance.getAllAccounts();

    (currentAccounts[0].idTokenClaims as any).groups.forEach((group: any) => {
      this.userGroupsArray.push(group);
    });
  }
}