// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//The development environment config contains variables required to run the application in development.
//Environment config is accessed by importing the environment object into any Angular service of component
//with the line import { environment } from '@environments/environment' and accessing properties on the environment object

export const environment = {
  production: false,
  //authApiUrl: 'http://localhost:59649',//Local host Auth Web API for testing
  //authApiUrl: 'http://mk3-web:8010/api',//IIS Auth Web API
  wgilAPIBaseUrl: 'https://wgil-api-management.azure-api.net/v1/api/',
  //wgilAPIBaseUrl: 'https://localhost:44345/api/',
  //authApiUrl: 'https://wgil-api-management.azure-api.net/api',//Azure Auth Web API
  //casesApiUrl: 'http://mk3-web:8009/api/WgiCasesLatests'//IIS Web API for Latest Cases
  //casesApiUrl:'https://wgil-api-management.azure-api.net/v1/api/WgicasesLatests', //Azure Web API for Latest Cases
  //claimsAllApiUrl:'https://wgil-api-management.azure-api.net/v1/api/WgicasesAlls', //Azure Web API for All Cases
  //casesApiUrl: 'https://localhost:44345/api/WgicasesLatests',
  commonAPIUrl: 'https://wgil-api-management.azure-api.net/Common', //Azure Web API for Common
  //casesByConsultantApiUrl:'https://wgil-api-management.azure-api.net/v1/api/WgiCasesByConsultant', //Azure Web API for WgiCasesByConsultant
  //catReportApiUrl: 'https://wgil-api-management.azure-api.net/v1/api/Cats', //Azure Web API for CAT Reports
  //e2eEoLReportApiUrl: 'https://wgil-api-management.azure-api.net/v1/api/E2eEol', //Azure Web API for E2E EOL Reports
  //catsListApiUrl: 'https://wgil-api-management.azure-api.net/v1/api/CatsLists', //Azure Web API for the list of CATs
  //claimNotesListApiUrl:'https://wgil-api-management.azure-api.net/v1/api/WgilClaimNotesLists', //Azure Web API for the list of Claim Notes'
  // claimContactApiUrl:
  //   'https://wgil-api-management.azure-api.net/v1/api/ClaimContacts/Contacts', //Azure Web API for the list of Claim Contacts'
  //claimContactRoleApiUrl: 'https://wgil-api-management.azure-api.net/v1/api/ClaimContacts/ContactRole', //Azure Web API for the list of Claim Contact Roles'
  datumConnectApiUrl:'https://wgil-api-management.azure-api.net/datumconnect/api/',
  //datumConnectApiUrl:'https://localhost:7025/api/' //local Web API for Datum connect',
  contractorAPIMainUrl: 'https://wgil-api-management.azure-api.net/contractor/api/',//Azure Web API for Data Access
  //contractorAPIMainUrl: 'https://localhost:44346/api/',//Local

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
