<div class="app-container">

  <div class="portal-header">
    <h5 style="text-align: center;">Reports</h5>
  </div>

  <div class="home-container">
    <div class="p-4">
      <div class="container">
        <button [routerLink]="'./claim-received-summary'" class="btn btn-sm btn-danger">Claims Summary</button>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <button [routerLink]="'./open-claims-list'" class="btn btn-sm btn-danger">My Open Claims</button>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <button [routerLink]="'./all-open-claims'" class="btn btn-sm btn-danger">All Open Claims</button>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <button [routerLink]="'./cat-claims'" class="btn btn-sm btn-danger">CAT Claims</button> &nbsp;&nbsp;
        &nbsp;&nbsp;
        <button [routerLink]="'./e2e-eol'" class="btn btn-sm btn-danger">End To End - Escape of Liquid</button>
        &nbsp;&nbsp; &nbsp;&nbsp;

        <!-- <p><a routerLink="./e2e-eol" *ngIf="user.roleID === 2">End To End - Escape of Liquid</a></p>
        <p><a routerLink="./cat-claims" *ngIf="user.roleID === 2">CAT Claims</a></p> -->
      </div>
    </div>
  </div>
